<div class="form-field flex-col"
  [class.invalid]="isTouched && invalid"
  style="row-gap: 2px"
  (click)="onTouched()">
  <div class="flex-row gap-1 middle">
    <img class="icon-relation"
      alt="relation icon"
      src="./assets/img/icon-relation.svg">

    <label *ngIf="label"
      class="label"
      [innerHTML]="label">
    </label>
    <sup *ngIf="required" class="label">*</sup>

    <i *ngIf="labelIconRight"
      [ngClass]="labelIconRight"
      (click)="labelIconRightClick.emit($event)"
      [pTooltip]="labelIconRightTooltip">
    </i>

    <div class="flex-row gap-1">
      <lc-button *ngIf="relationLinkActive && relationLinkClick?.observers?.length"
        (click)="relationLinkClick.emit()"
        icon="fas fa-eye"
        [pTooltip]="'View' | translate"
        tooltipPosition="top"
        type="tertiary">
      </lc-button>

      <lc-button *ngIf="!disabled && editMode && dropdownModeCanAdd && dropdownMode"
        (click)="relationEditClick.emit()"
        icon="fas fa-plus"
        [pTooltip]="'Add New' | translate"
        tooltipPosition="top"
        type="tertiary">
      </lc-button>

      <lc-button *ngIf="!disabled && editMode"
        (click)="onRelationEditClick()"
        icon="fas fa-pencil-alt"
        [pTooltip]="'Change' | translate"
        tooltipPosition="top"
        type="tertiary">
      </lc-button>
    </div>
  </div>

  <div *ngIf="!dropdownMode"
    class="value flex-row middle">
    <div class="flex-row middle gap-1">
      <i *ngIf="iconLeft"
        [ngClass]="iconLeft">
      </i>

      <div
        [class.copy-on-hover]="allowCopyOnHover && value != null"
        (mousedown)="copyToClipboardMouseDown($event)"
        (click)="copyToClipboardClick($event)"
        [innerHTML]="value != null ? value : noValueHtml"
        [pTooltip]="value?.length > 50 ? value : ''"
        tooltipPosition="bottom">
      </div>

      <i *ngIf="iconRight"
        [ngClass]="iconRight"
        (click)="iconRightClick.emit()">
      </i>
    </div>
  </div>

  <p-dropdown *ngIf="dropdownMode"
    [appendTo]="appendTo"
    styleClass="dropdown"
    panelStyleClass="dropdown-panel"
    [autoDisplayFirst]="false"
    [filter]="(relationOptions || []).length > 7"
    [optionLabel]="optionLabel"
    [options]="relationOptions"
    [placeholder]="!relationOptions ? ('Loading...' | translate) : placeholder"
    [(ngModel)]="relationWebObject"
    (ngModelChange)="onDropdownChange()"
    [required]="required"
    [showClear]="dropdownShowClear"
    [virtualScroll]="true">
    <ng-template let-item pTemplate="selectedItem">
      <div class="flex-row middle">
        <div *ngIf="item.icon" [ngClass]="item.icon"></div>

        <div class="dropdown-label">
          {{ optionLabel ? (item.value ? (item.value | lcDeepFind:optionLabel) : (item | lcDeepFind:optionLabel)) : item.label }}
        </div>
      </div>
    </ng-template>

    <ng-template let-item pTemplate="item">
      <div class="flex-row middle"
        style="min-height: 2rem"
        [style.padding-left]="group ? '1rem': '0'">
        <div *ngIf="item.icon" [ngClass]="item.icon"></div>

        <div [class.ellipsis]="item.title || item.description">
          {{ optionLabel ? (item.value ? (item.value | lcDeepFind:optionLabel) : (item | lcDeepFind:optionLabel)) : item.label }}
        </div>
      </div>

      <div class="description"
        [innerHTML]="item.title || item.description || ''">
      </div>
    </ng-template>
  </p-dropdown>
</div>
