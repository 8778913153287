
// I'm "initializing" all fields to be able to use "reflection" and get all class fields
export class WebCenterTypeMemberSpecialValue {

  guidId?: string = undefined;
  name?: string = undefined;
  readOnly?: boolean = undefined;
  value?: any = undefined;


  constructor(item?: Partial<WebCenterTypeMemberSpecialValue>) {
    Object.assign(this, item);
  }
}
