import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, Output } from '@angular/core';
import { WebObject, WorkspaceSolutionType } from 'app/center-v2/shared/models';
import { WorkspaceSolutionTypeMember } from 'app/center-v2/shared/models/workspace-solution-type/workspace-solution-type-member.model';
import { BaseGridViewerComponent } from 'app/shared/components/base/base-grid-viewer.component';
import { SessionService } from 'app/shared/services/app';
import { JsonUtils } from 'app/shared/utils';




@Component({
  selector: 'lc-web-object-grid-viewer',
  templateUrl: './web-object-grid-viewer.component.html',
  styleUrls: ['./web-object-grid-viewer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class WebObjectGridViewerComponent extends BaseGridViewerComponent {

  @Input() actionButtonDisabled: boolean;
  @Input() actionButtonIcon: string;
  @Input() actionButtonLabel: string;
  @Input() actionButtonType: string = 'primary';
  @Input() autoGroupColumnDef: any;
  @Input() autoHeight: boolean;
  @Input() autoSizeColumns: boolean;
  @Input() disableInitialSort: boolean;
  @Input() getDataPath: (params: any) => string[];
  @Input() gridCols: WebObject[];
  @Input() getGridReady: (params: any) => void;
  @Input() getRowHeight: (params: any) => number;
  @Input() getRowId: (params: any) => string;
  @Input() getServerSideGroupKey: (params: any) => string;
  @Input() gridRows: WebObject[];
  @Input() groupDefaultExpanded: number;
  @Input() hideExportButton: boolean;
  @Input() hideSearch: boolean;
  @Input() isRowSelectable: (params: any) => boolean;
  @Input() isServerSideGroup: (params: any) => boolean;
  @Input() label: string;
  @Input() lastUpdateDate: Date;
  @Input() lastUpdateFailed: boolean;
  @Input() overlayNoRowsTemplate: string;
  @Input() pinnedBottomRows: WebObject[];
  @Input() rowDragManaged: boolean;
  @Input() rowSelection: 'single' | 'multiple';
  @Input() set selectedRows(value: any[]) {
    if (this._selectedRows !== value) {
      this.selectGridRows(value);
    }

    this._selectedRows = value;
  }
  get selectedRows(): any[] {
    return this._selectedRows;
  }
  private _selectedRows: any[];
  @Input() serverSideDataSource: { getRows: (params: any) => void };
  @Input() solutionType: WorkspaceSolutionType;
  @Input() theme: string = 'ag-theme-balham';
  @Input() treeData: boolean;
  @Output() actionButtonClick = new EventEmitter<any>();
  @Output() refreshCallback = new EventEmitter<void>();
  @Output() rowClicked = new EventEmitter<any>();
  @Output() rowDoubleClicked = new EventEmitter<any>();
  @Output() rowDragEnd = new EventEmitter<any>();
  @Output() selectedRowsChange = new EventEmitter<any[]>();

  constructor(
    cdr: ChangeDetectorRef,
    sessionService: SessionService,
  ) {
    super(cdr, sessionService);

    this.getRowStyle = this.getRowStyle.bind(this);
    setTimeout(() => {
      this.refresh();
    }, 100)
  }

  refresh(options?: any) {
    this.cdr.markForCheck();
  }

  getRowStyle(params: any) {
    if (!this.solutionType?.style?.grid?.rowColorMember) return {}

    const path = (this.solutionType?.style?.grid?.rowColorMember as WorkspaceSolutionTypeMember)?.getWeb2ObjectPath();
    const value = JsonUtils.deepFind(params?.data, path);
    const colorMapping = (this.solutionType?.style?.grid?.rowColorMappings || []).find((item: any) => {
      return item.value === (value || '').toString();
    });
    if (colorMapping) return colorMapping;

    return {};
  }

  private selectGridRows(webObjects: WebObject[]) {
    if (!webObjects) return;

    setTimeout(() => {
      if (!this.grid) return;

      this.grid.agGrid.api.forEachNode((node: any) => {
        node.setSelected((webObjects || []).some(wo => wo?.guidId === node.data?.guidId));
      });
      this.grid.refresh();
      this.cdr.markForCheck();
    }, 100);
  }

  selectionChanged(params: any) {
    this._selectedRows = params.api.getSelectedRows() || [];
    this.selectedRowsChange.emit(this._selectedRows);
    this.cdr.markForCheck();
  }

}
