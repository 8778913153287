import { ICellEditorAngularComp } from 'ag-grid-angular';
import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { CaseUtils } from 'app/shared/utils/case.utils';
import { EnumUtils } from 'app/shared/utils/enum.utils';
import { GuidUtils } from 'app/shared/utils/guid.utils';
import { SelectItem } from 'primeng/api';
import { GridCellEditorFormField } from '../grid-celleditor-form-field/grid-celleditor-form-field.component';
import { GridCellRendererText } from '../grid-cellrenderer-text/grid-cellrenderer-text.component';
import { BaseGridComponent } from '../../base/base-grid.component';
import { SessionService } from 'app/shared/services/app';
import { WebCenterTypeRelationMember } from 'app/center-v2/shared/models/web-base/web-center-type/web-center-type-relation-member.model';
import { WebCenterTypeMemberNativeType } from 'app/center-v2/shared/models/web-base/web-center-type/web-center-type-member-native-type.enum';
import { WebCenterTypeMemberSpecialValueType } from 'app/center-v2/shared/models/web-base/web-center-type/web-center-type-member-special-value-type.enum';
import { WebCenterTypeMemberWeb2Access } from 'app/center-v2/shared/models/web-base/web-center-type/web-center-type-member-web-access.enum';
import { GridCellEditorCenterTypeMemberSpecialValues } from '../grid-celleditor-center-type-member-special-values/grid-celleditor-center-type-member-special-values.component';


@Component({
  selector: 'lc-grid-celleditor-center-type-relation-members',
  templateUrl: 'grid-celleditor-center-type-relation-members.component.html',
  styleUrls: ['grid-celleditor-center-type-relation-members.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GridCellEditorCenterTypeRelationMembers extends BaseGridComponent implements ICellEditorAngularComp, AfterViewInit {

  readonly ignoreColumns: string[] = ['guidId'];
  readonly sortByColumn = '';

  params: any;
  label: string;
  originalValue: WebCenterTypeRelationMember[];
  relationMembers: WebCenterTypeRelationMember[];

  constructor(
    cdr: ChangeDetectorRef,
    sessionService: SessionService,
    private translateService: TranslateService,
  ) {
    super(cdr, sessionService);

    this.label = this.translateService.instant('Relation Members');
    this.gridCols = this.buildGridCols();
  }

  ngAfterViewInit() {

  }

  agInit(params: any): void {
    this.params = params;

    if (
      !this.params?.data ||
      !this.params.data[this.params.colDef?.field]
    ) {
      this.stopEditing();
      return;
    }

    this.relationMembers = this.params.data[this.params.colDef.field];
    this.originalValue = JSON.parse(JSON.stringify(this.relationMembers));

    this.refresh();
  }

  refresh(options?: any) {
    if (this.relationMembers) {
      this.gridRows = this.relationMembers || [];

      this.cdr.markForCheck();
    }
  }

  private buildGridCols(): any[] {
    const gridCols: any[] = [];

    for (const key of Object.keys(new WebCenterTypeRelationMember())) {
      if (this.ignoreColumns.indexOf(key) >= 0) continue;

      let editorOptions: SelectItem[] = [];
      let editorType: string = 'text';
      switch (key) {
        case 'web2Member':
          editorType = 'checkbox';
          break;
        case 'nativeType':
          editorOptions = EnumUtils.toSelectItemArray(WebCenterTypeMemberNativeType);
          editorType = 'dropdown';
          break;
        case 'specialValuesType':
          editorOptions = EnumUtils.toSelectItemArray(WebCenterTypeMemberSpecialValueType);
          editorType = 'dropdown';
          break;
        case 'web2Access':
          editorOptions = EnumUtils.toSelectItemArray(WebCenterTypeMemberWeb2Access).slice(1); // Remove 'None'
          editorType = 'multi-select-flags';
          break;
      }
      gridCols.push({
        headerName: this.translateService.instant(CaseUtils.capitalizeFirstLetter(key)),
        field: key,
        valueFormatter: (params: any) => {
          if (key === 'specialValues') {
            return `${(params.data[key] || []).length} ${this.translateService.instant('Values')}`;
          }
          return params.data[key];
        },
        cellEditor: key === 'specialValues' ? GridCellEditorCenterTypeMemberSpecialValues : GridCellEditorFormField,
        cellEditorParams: {
          options: editorOptions,
          type: editorType,
        },
        cellRenderer: GridCellRendererText,
        cellRendererParams: {
          filter: () => { return this.gridQuickFilter; },
          isDate: key.toLowerCase().indexOf('tick') >= 0,
        },
        editable: key !== this.sortByColumn,
        minWidth: 120,
        sort: key === this.sortByColumn ? 'asc' : undefined,
      });
    }

    return gridCols;
  }

  addNew() {
    let newMemberId = 1;
    for (const ctm of this.relationMembers || []) {
      newMemberId = Math.max(newMemberId, (ctm.memberId || 0) + 1);
    }

    const newCenterTypeRelationMember = new WebCenterTypeRelationMember({
      guidId: GuidUtils.new(),
      memberId: newMemberId,
      version: 0,
    });
    this.relationMembers.push(newCenterTypeRelationMember);

    const rowDataTransaction = {
      add: [newCenterTypeRelationMember],
    };
    this.grid.agGrid.api.applyTransactionAsync(rowDataTransaction);
    this.cdr.markForCheck();
  }

  getValue(): any {
    return this.params?.value; // this.relationMembers;
  }

  isPopup(): boolean {
    return true;
  }

  stopEditing() {
    this.params.api.stopEditing(false);
  }

}


