import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, EventEmitter, forwardRef, Input, Output } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { WebObject } from 'app/center-v2/shared/models';
import { GenericService } from 'app/center-v2/shared/services';
import { AppSharedV2Module } from 'app/shared/app-shared-v2.module';
import { NotificationService } from 'app/shared/services/app/notification.service';
import { InputTextModule } from 'primeng/inputtext';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';


@Component({
  standalone: true,
  imports: [
    AppSharedV2Module,
    InputTextModule,
  ],
  selector: 'lc-form-field-html',
  templateUrl: 'form-field-html.component.html',
  styleUrls: ['./form-field-html.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => FormFieldHtmlComponent),
    multi: true
  }],
})
export class FormFieldHtmlComponent implements ControlValueAccessor, AfterViewInit {

  readonly defaultHtml =
`<style>
  .content {
    height: 100%;
  }
</style>

<div class="content">

</div>`;


  @Input() appendTo: any;
  @Input() disabled: boolean;
  @Input() editMode: boolean;
  @Input() iconLeft: string;
  @Input() iconRight: string;
  @Input() label: string;
  @Input() labelIconRight: string;
  @Input() labelIconRightTooltip: string;
  @Input() max: number;
  @Input() required: boolean;
  @Input() separator: string;

  @Output() labelIconRightClick = new EventEmitter<MouseEvent>();

  onChange: (x: any) => {};
  onTouched: () => {};

  htmlChanged = new Subject<string>();
  invalid: boolean;
  isFocused = false;
  isTouched: boolean;
  previewHTML: SafeHtml;
  value: string;
  values: string[];

  constructor(
    private cdr: ChangeDetectorRef,
    private domSanitizer: DomSanitizer,
    public el: ElementRef,
    private notificationService: NotificationService,
    private translateService: TranslateService,
  ) {
    this.htmlChanged
    .pipe(
      debounceTime(300)
    )
    .subscribe(() => { this.onHtmlChange(); });
  }

  // From ControlValueAccessor interface
  writeValue(value: any) {
    if (this.value === value) return;

    this.value = value;
    this.cdr.markForCheck();

    setTimeout(() => {
      this.onHtmlChange();
      this.cdr.markForCheck();
    }, 100);
  }

   // From ControlValueAccessor interface
   registerOnChange(fn: any) {
    this.onChange = (value: any) => {
      this.invalid = this.required && (value == null || value === '' || (!(value instanceof WebObject) && !value?.length));
      return fn(value);
    };
  }

  // From ControlValueAccessor interface
  registerOnTouched(fn: any) {
    this.onTouched = () => {
      this.isTouched = true;
      return fn();
    };
  }

  ngAfterViewInit() {
    this.refresh();
  }

  refresh() {
    this.cdr.markForCheck();
  }

  onHtmlChange() {
    if ((this.value || '').indexOf('<script>') >= 0) {
      this.notificationService.warn(
        this.translateService.instant('Not allowed'),
        this.translateService.instant('Script tags are not allowed')
      )
    }
    this.value = (this.value || '')
    .replace(
      /<script>/g,
      '<div>'
    )
    .replace(
      /<\/script>/g,
      '</div>'
    );
    this.previewHTML = this.domSanitizer.bypassSecurityTrustHtml(this.value);

    this.onChange(this.value);

    this.cdr.markForCheck();
  }

}
