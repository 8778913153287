import { WebCenterTypeMember } from './web-center-type-member.model';
import { WebCenterTypeRelationMember } from './web-center-type-relation-member.model';
import { WebCenterTypeRelationWebNewAttribute } from './web-center-type-relation-web-new-attribute.enum';


// I'm "initializing" all fields to be able to use "reflection" and get all class fields
export class WebCenterTypeRelation {
  typeGuidId: string = undefined;
  version: number = undefined;
  typeTreeRelationGuidId: string = undefined;
  name: string = undefined;
  dataName: string = undefined;
  isRootRelation: boolean = undefined;
  allowDuplicatedChildren: boolean = undefined;
  parentTypeGuidId: string = undefined;
  childTypeGuidId: string = undefined;
  collectionRelation: boolean = undefined;
  relationMembers: WebCenterTypeMember[];
  fallbackRelationTypeRelationGuidId: string = undefined;
  lazyRelation: boolean = undefined;
  web2Access: number = undefined;
  web2LazyLoad: boolean = undefined;
  web2NewAttribute: WebCenterTypeRelationWebNewAttribute = undefined;
  web2SpecialRelationFilter: any = undefined;
  web2Relation: boolean = undefined;

  constructor(item?: Partial<WebCenterTypeRelation>) {
    Object.assign(this, item);
  }
}

