import { SolutionType } from 'app/shared/models';
import { VariableToolboxGroupItem } from '../variable-toolbox/variable-toolbox-group-item.model';

export const enum FieldType {
  Unknown = 0,
  Solution = 1,
  Property = 2,
  Relation = 3,
  SolutionType = 4,
  Collection = 5,
}

export enum FieldDirection {
  In = 0,
  Out = 1,
  InAndOut = 2
}

export class Field {

  fieldGuidId: string;
  allowNull: boolean;
  isDirty: boolean;
  name: string;
  direction: FieldDirection;
  readOnly: boolean;
  solutionTypeGuidId: string;
  systemField: boolean;
  targetTypeGuidId: string;
  webFieldType: FieldType;
  isStaticTypeField: boolean;

  constructor(field?: Field) {
    Object.assign(this, field);
  }

  getValue(arg1?: any, arg2?: any): any {
    return 'this is wrong...';
  }

  getVariableValidHint(solutionTypes: SolutionType[]): string {
    return 'Not supported supported by this field.';
  }

  isVariableValidHere(v: VariableToolboxGroupItem, solutionTypes: SolutionType[]): string {
    return 'Not supported supported by this field.';
  }

}
