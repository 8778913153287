import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { BaseDialog } from 'app/shared/dialogs/base/base.dialog';
import { SessionService } from 'app/shared/services/app';
import { SelectItem } from 'primeng/api';




@Component({
  selector: 'lc-prompt-dialog',
  templateUrl: './prompt.dialog.html',
  styleUrls: ['./prompt.dialog.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PromptDialog extends BaseDialog {

  @ViewChild(NgForm, { static: false }) form: NgForm;

  data: {
    controls: any[];
    label: string;
    options: SelectItem[] | any[];
    optionLabel: string;
    required: boolean;
    title: string;
    type: string;
    value: string;
  };

  controls: any[];


  constructor(
    cdr: ChangeDetectorRef,
    sessionService: SessionService,
  ) {
    super(cdr, sessionService);
  }

  init(data: any) {
    this.data = this.data || {} as any;

    this.controls = this.data.controls || [];
    if (!this.data.controls?.length) {
      this.data.required = this.data.required === false ? false : true;
      this.controls.push(this.data);
    }
  }

  submit(form: NgForm) {
    if (!form.valid) return;

    if (!this.data.controls?.length) {
      this.close(this.data?.value != null ? this.data?.value : '');
    } else {
      const result = this.controls.reduce((previousValue: any, currentValue: any) => {
        previousValue[currentValue.name] = currentValue.value;
        return previousValue;
      }, {});
      this.close(result);
    }
    form.reset();
  }

}
