
<p-dialog appendTo="body"
  styleClass="prompt-dialog"
  [header]="data?.title"
  [closeOnEscape]="false"
  [modal]="true"
  [dismissableMask]="true"
  [(visible)]="isVisible">

  <form #form="ngForm"
    class="flex-col gap-2"
    (ngSubmit)="submit(form)"
    novalidate>

    <lc-form-field *ngFor="let control of controls || []; let i = index;"
      appendTo="body"
      [autoFocus]="i === 0"
      [editMode]="true"
      [label]="control.label"
      [name]="control.name || 'prompt'"
      [ngModel]="control.value"
      (ngModelChange)="control.value = $event"
      [options]="control.options || []"
      [optionLabel]="control.optionLabel"
      [required]="control.required"
      [type]="control.type || 'text'">
    </lc-form-field>

  </form>

  <p-footer class="flex-row gap-1 middle">
    <div class="flex-1"></div>

    <lc-button
      [label]="'Cancel' | translate"
      (click)="dismiss()"
      type="secondary">
    </lc-button>

    <lc-button
      [disabled]="form.invalid"
      [isFormSubmit]="true"
      [label]="'Ok' | translate"
      (click)="form.ngSubmit.emit()"
      type="primary">
    </lc-button>
  </p-footer>
</p-dialog>
