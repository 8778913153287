import { ICellEditorAngularComp } from 'ag-grid-angular';
import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, ViewChild, EventEmitter } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { GridUtils, JsonUtils } from 'app/shared/utils';
import { FormFieldComponent } from '../../form-field/form-field.component';
import { Observable, Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { SelectItem } from 'primeng/api';


@Component({
  selector: 'lc-grid-celleditor-form-field',
  templateUrl: 'grid-celleditor-form-field.component.html',
  styleUrls: ['grid-celleditor-form-field.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GridCellEditorFormField implements ICellEditorAngularComp, AfterViewInit {

  @ViewChild(FormFieldComponent, { static: false }) formFieldComponent: FormFieldComponent;

  params: any;

  cellValueChanged: Subject<void>;
  options: SelectItem[];
  originalValue: any;

  constructor(
    private cdr: ChangeDetectorRef,
    private translateService: TranslateService,
  ) {
    this.cellValueChanged = new Subject<void>();
    this.cellValueChanged
    .pipe(
      debounceTime(500)
    )
    .subscribe(() => {
      this.onCellValueChanged();
    });
  }

  ngAfterViewInit() {
    setTimeout(() => {
      if (this.formFieldComponent) {
        this.formFieldComponent.disableKeysPropagation((event: KeyboardEvent) => {
          if (event.key === 'Tab') {
            event.stopPropagation();

            this.params.api.tabToNextCell();
          }
        });

        this.formFieldComponent.tryToFocusInputElement();

        if (this.params.type === 'dropdown') {
          this.formFieldComponent.showPicker();
          this.cdr.markForCheck();
        } else if (this.params.type === 'multi-select' || this.params.type === 'multi-select-flags') {
          this.formFieldComponent.showPicker();
          this.cdr.markForCheck();
        } else if (this.params.type === 'date') {
          this.formFieldComponent.showPicker();
          this.cdr.markForCheck();
        }
      }
    }, 10);
  }

  agInit(params): void {
    this.params = params;

    this.params.disabled = GridUtils.getParamValue(this.params, this.params.disabled);
    this.params.options = GridUtils.getParamValue(this.params, this.params.options);
    this.params.maxNumber = GridUtils.getParamValue(this.params, this.params.maxNumber);
    this.params.minNumber = GridUtils.getParamValue(this.params, this.params.minNumber);
    this.params.pattern = GridUtils.getParamValue(this.params, this.params.pattern);
    this.params.type = GridUtils.getParamValue(this.params, this.params.type);
    this.params.type = (this.params.type || 'text').toLowerCase();
    this.params.width = GridUtils.getParamValue(this.params, this.params.width);

    this.params.options = GridUtils.getParamValue(this.params, this.params.options);
    if (this.params.options instanceof Observable) {
      this.params.options.subscribe((result: any[]) => {
        this.options = result || [];

        this.params.value = (this.options.find((item: SelectItem) => {
          return this.params.value && item.label === this.params.value;
        }) || this.options.find((item: SelectItem) => {
          return this.params.value && item.value.guidId === this.params.value.guidId;
        }) || {} as SelectItem).value;
        this.cdr.markForCheck();
      })
    } else {
      this.options = this.params.options;
      if (['dropdown', 'multi-select', 'multi-select-flags'].indexOf(this.params.type) >= 0 && this.params.options?.length) {
        this.params.value = JsonUtils.deepFind(this.params.data, this.params.colDef.colId || this.params.colDef.field);
      }
    }

    this.originalValue = this.params.value;

    if (
      GridUtils.getParamValue(this.params, this.params.colDef.cellRendererParams?.disabled) &&
      this.params.stopEditing
    ) {
      this.params.stopEditing(true);
    }
  }

  getValue(): any {
    return this.params?.value;
  }

  isPopup(): boolean {
    return true;
  }

  getPopupPosition(): 'over' | 'under' | undefined {
    return 'over';
  }

  onCellValueChanged() {
    if (this.params.value !== this.originalValue) {
      this.params.newValue = this.params.value;
      this.params.oldValue = this.originalValue;

      const fieldSplit = (this.params.colDef.colId || this.params.colDef.field).split('.');
      const fieldKey = fieldSplit[fieldSplit.length - 1];
      if (fieldKey !== 'web2Object') { // means we're NOT updating a relation field...
        const fieldPath = fieldSplit.slice(0, fieldSplit.length - 1).join('.');
        const parentField = fieldPath ? JsonUtils.deepFind(this.params.data, fieldPath) : this.params.data;
        parentField[fieldKey] = this.params.newValue;
      }

      /* if (this.params?.colDef?.valueSetter) { // commented this out because this was being called once here and another time directly by the grid?
        this.params?.colDef?.valueSetter(this.params);
      } else */if (this.params?.cellValueChanged) {
        this.params.cellValueChanged(this.params);
      }
    }
    this.originalValue = this.params.value;

    // if (this.params.stopEditing) this.params.stopEditing();
  }

}
