import { ObjectOrValue } from 'app/shared/models/solution-type/solution-type-member.model';
import { ArrayUtils, CaseUtils, GuidUtils, JsonUtils } from 'app/shared/utils';
import { WorkspaceSolutionType, WorkspaceSolutionTypeMember } from '../..';
import { WebCenterTypeMember } from './web-center-type-member.model';
import { WebCenterTypeRelation } from './web-center-type-relation.model';
import { WebCenterTypeMemberSpecialValueType } from './web-center-type-member-special-value-type.enum';
import { WebCenterTypeMemberSpecialValue } from './web-center-type-member-special-value.model';
import { WebCenterTypeCollection } from './web-center-type-collection.model';

// I'm "initializing" all fields to be able to use "reflection" and get all class fields
export class WebCenterType {

  guidId: string = undefined;
  groupGuidId: string = undefined;
  typeGuidId: string = undefined;
  centerTypeId: number = undefined;
  name: string = undefined;
  dataName: string = undefined;
  access: any = undefined;
  isTreeRoot: boolean = undefined;
  isRootUntyped: boolean = undefined;
  rootTypeGuidId: string = undefined;
  defaultTypeOfString: string = undefined;
  altDefaultTypeOfString: string = undefined;
  web2Name: string = undefined;
  web2NewBehaviour: any = undefined;
  web2NewSiteBehaviour: any = undefined;
  web2Ready: boolean = undefined;
  requireEnvironment: boolean = undefined;
  defaultEnvironmentGuidId: string = undefined;
  environmentOnly: boolean = undefined;
  isSysIdType: boolean = undefined;
  sysIdName: string = undefined;
  sysIdObjectTypeMemberId: string = undefined;
  isLanguageType: boolean = undefined;
  parentRelationGuidId: string = undefined;
  version: string = undefined;
  tick: number = undefined;
  createdTick: number = undefined;
  locked: boolean = undefined;
  environmentGuidId: string = undefined;
  siteGuidId: string = undefined;
  id?: string = undefined;
  // _id?: string = undefined;
  // _type?: string = undefined;

  members: WebCenterTypeMember[];
  relations: WebCenterTypeRelation[];
  collections: any[];
  subTypeGuidIds: string[] = undefined;
  structureType: boolean = undefined;
  structureParentMemberId?: number = undefined;
  structureParentRelationTypeGuidId?: string = undefined;


  constructor(item?: Partial<WebCenterType>) {
    Object.assign(this, item);

    this.collections = (this.collections || []).map(x => new WebCenterTypeCollection(x));
    this.members = (this.members || []).map(x => new WebCenterTypeMember(x));
    this.relations = (this.relations || []).map(x => new WebCenterTypeRelation(x));

    this.addAdditionalClientSideInfo();
  }

  static getListFromWorkspaceSolutionType(solutionTypes: WorkspaceSolutionType[], solutionType: WorkspaceSolutionType, membersKeys: string[], level = 0): string[] {
    const result = [];

    for (const membersKey of membersKeys || []) {
      const members = JsonUtils.deepFind(solutionType, membersKey, true, []) as WorkspaceSolutionTypeMember[];
      for (const member of members || []) {
        if (member.objectOrValue === ObjectOrValue.Object) {
          const nestedST = (solutionTypes || []).find(st => st.isDefault && st.baseSolutionTypeGuidId === member.subMembers[0].toTypeGuidId);
          if (nestedST && level < 3) result.push(...WebCenterType.getListFromWorkspaceSolutionType(solutionTypes, nestedST, membersKeys, ++level));
        }

        for (let i = 0; i <= member.subLevels; i++) {
          if (!GuidUtils.isNullOrEmpty(member.subMembers[i].fromTypeGuidId)) result.push(member.subMembers[i].fromTypeGuidId);
          if (!GuidUtils.isNullOrEmpty(member.subMembers[i].toTypeGuidId)) result.push(member.subMembers[i].toTypeGuidId);
        }
      }
    }

    return ArrayUtils.uniqBy(result, x => x);
  }

  private addAdditionalClientSideInfo() {
    // need to add an extra $key to the member objects -> lowercase name
    this.members = (this.members || [])
    .map((m: WebCenterTypeMember) => {
      m.$key = CaseUtils.camelize(m.name, true);
      return m;
    });

    for (const relation of this.relations || []) {
      for (const member of relation.relationMembers || []) {
        member.$key = (member.name || '').toLowerCase();
        this.mapServerTypesAndTexts(member);
      }
    }

    // then, I map out the server types to HTML types...or special types
    for (const member of this.members || []) {
      this.mapServerTypesAndTexts(member);
    }

    // for (const relation of this.relations || []) {
    //   if (relation.relationMembers) {
    //     for (const member of relation.relationMembers || []) {
    //       this.mapServerTypesAndTexts(member);
    //     }
    //   }
    // }
  }

  private mapServerTypesAndTexts(member: WebCenterTypeMember) {
    if (member.$fieldType) return; // already mapped out

    if (member.specialValues?.length && member.specialValuesType === WebCenterTypeMemberSpecialValueType.Normal) {
      member.$fieldType = 'dropdown';
      member.$fieldOptions = member.specialValues.map((v: WebCenterTypeMemberSpecialValue) => {
        return { value: v.value, label: v.name, disabled: v.readOnly };
      });
    } else if (member.specialValues?.length && member.specialValuesType === WebCenterTypeMemberSpecialValueType.Flags) {
      member.$fieldType = 'multi-select-flags';
      member.$fieldOptions = member.specialValues.map((v: WebCenterTypeMemberSpecialValue) => {
        return { value: v.value, label: v.name, disabled: v.readOnly };
      });
    } else if (member.specialMemberType === 'DateTime') {
      member.$fieldType = 'date';
    } else if (member.specialMemberType === 'DayIndex') {
      member.$fieldType = 'date';
    } else if (member.specialMemberType === 'TimeIndex') {
      member.$fieldType = 'time';
    } else if (member.specialMemberType === 'TimeSpanHour') {
      member.$fieldType = 'time';
    } else if (member.specialMemberType === 'WizardJson' || member.specialMemberType === 'WizardUI') {
      member.$fieldType = 'json';
    } else if (member.specialMemberType) {
      member.$fieldType = member.specialMemberType.toLowerCase();
    } else {
      switch (member.nativeType) {
        case 'System.String':
          member.$fieldType = 'text';
          break;
        case 'System.Int32':
        case 'System.Int64':
        case 'System.Decimal':
        case 'System.Double':
          member.$fieldType = 'number';
          break;
        case 'System.Boolean':
          member.$fieldType = member.allowNull ? 'checkbox3' : 'checkbox';
          break;
      }
    }
  }

}
