import { SelectItem } from 'primeng/api/selectitem';
import { WebCenterTypeMemberSpecialValueType } from './web-center-type-member-special-value-type.enum';
import { WebCenterTypeMemberWeb2Access } from './web-center-type-member-web-access.enum';
import { WebCenterTypeMemberSpecialValue } from './web-center-type-member-special-value.model';

// I'm "initializing" all fields to be able to use "reflection" and get all class fields
export class WebCenterTypeMember {
  guidId: string = undefined;
  memberId: number = undefined;
  name: string = undefined;
  nativeType: string = undefined;
  nativeSize: number = undefined;
  required: boolean = undefined;
  isUnique: boolean = undefined;
  languageMember: boolean = undefined;
  specialMemberType: string = undefined;
  specialValues: WebCenterTypeMemberSpecialValue[];
  specialValuesType: WebCenterTypeMemberSpecialValueType = undefined;
  uoM: string = undefined;
  web2Member: boolean = undefined;
  web2Access: WebCenterTypeMemberWeb2Access = undefined;
  allowNull: boolean = undefined; // to be used for example on Boolean types (3-state-checkbox)

  $key: string;
  $fieldType: string;
  $fieldOptions: SelectItem[];
  $uoMAlternative: { label: string, multiplier: number };

  constructor(item?: Partial<WebCenterTypeMember>) {
    Object.assign(this, item);

    this.specialValues = (this.specialValues || []).map(x => new WebCenterTypeMemberSpecialValue(x));
    this.$uoMAlternative = this.getUoMAlternativeSystem(this.uoM);
  }

  private getUoMAlternativeSystem(uom: string) {
    switch(uom?.toLowerCase()) {
      case 'dpi':
        return { label: 'dpm', multiplier: 39.37 };
      case 'dpm':
        return { label: 'dpi', multiplier: 0.0254 };
      case 'ips':
        return { label: 'mms', multiplier: 25.4 };
      case 'inch':
        return { label: 'mm', multiplier: 25.4 };
      case 'kg':
        return { label: 'lbs', multiplier: 2.20462 };
      case 'lbs':
        return { label: 'kg', multiplier: 0.45359 };
      case 'mm':
        return { label: 'inch', multiplier: 0.03937 };
      case 'mms':
        return { label: 'ips', multiplier: 0.03937 };
    }
  }
}

