export enum WebCenterTypeMemberNativeType {

  'System.Boolean' = 'System.Boolean',
  'System.Decimal' = 'System.Decimal',
  'System.Double' = 'System.Double',
  'System.Guid' = 'System.Guid',
  'System.Int32' = 'System.Int32',
  'System.Int64' = 'System.Int64',
  'System.Object' = 'System.Object',
  'System.String' = 'System.String',

}
