
<div #fieldWrapper [ngSwitch]="type">
  <lc-form-field-backoffice-platform-report-result-method *ngSwitchCase="'backofficeplatformreportresultmethod'"
    appendTo="body"
    [disabled]="disabled"
    [editMode]="editMode"
    [label]="label"
    [(ngModel)]="value"
    (ngModelChange)="onChange(value)">
  </lc-form-field-backoffice-platform-report-result-method>

  <lc-form-field-backoffice-platform-report-result-method *ngSwitchCase="'backofficerunsystemreportresultmethod'"
    appendTo="body"
    [disabled]="disabled"
    [editMode]="editMode"
    [label]="label"
    [(ngModel)]="value"
    (ngModelChange)="onChange(value)">
  </lc-form-field-backoffice-platform-report-result-method>

  <lc-form-field-days-of-week *ngSwitchCase="'daysofweek'"
    [appendTo]="appendTo"
    [disabled]="disabled"
    [editMode]="editMode"
    [label]="label"
    [(ngModel)]="value"
    (ngModelChange)="onChange(value)">
  </lc-form-field-days-of-week>

  <lc-form-field-emails *ngSwitchCase="'email'"
    [disabled]="disabled"
    [editMode]="editMode"
    [max]="1"
    [label]="label"
    [(ngModel)]="value"
    (ngModelChange)="onChange(value)"
    [required]="required"
    separator=";">
  </lc-form-field-emails>

  <lc-form-field-emails *ngSwitchCase="'emails'"
    [disabled]="disabled"
    [editMode]="editMode"
    [label]="label"
    [(ngModel)]="value"
    (ngModelChange)="onChange(value)"
    [required]="required"
    separator=";">
  </lc-form-field-emails>

  <lc-form-field-geojsongeometry *ngSwitchCase="'geojsongeometry'"
    [disabled]="disabled"
    [editMode]="editMode"
    [label]="label"
    [(ngModel)]="value"
    (ngModelChange)="onChange(value)">
  </lc-form-field-geojsongeometry>

  <lc-form-field-html *ngSwitchCase="'html'"
    #inputElement
    [disabled]="disabled"
    [editMode]="editMode"
    [label]="label"
    [(ngModel)]="value"
    (ngModelChange)="onChange(value)">
  </lc-form-field-html>

  <lc-form-field-json *ngSwitchCase="'json'"
    [disabled]="disabled"
    [editMode]="editMode"
    [label]="label"
    [(ngModel)]="value"
    (ngModelChange)="onChange(value)">
  </lc-form-field-json>

  <lc-form-field-relation *ngSwitchCase="'relation'"
    [allowCopyOnHover]="allowCopyOnHover"
    [disabled]="disabled"
    [editMode]="editMode"
    [label]="label"
    [(ngModel)]="value"
    (ngModelChange)="onChange(value)"
    [relationControlType]="relationControlType"
    (relationEditClick)="relationEditClick.emit($event)"
    [relationLinkActive]="relationLinkActive"
    (relationLinkClick)="relationLinkClick.emit($event)"
    [relationName]="relationName"
    [relationOptions]="relationOptions"
    [relationParentWebObject]="relationParentWebObject"
    [relationSolutionType]="relationSolutionType"
    [relationWebObject]="relationWebObject"
    [solutionTypeMember]="solutionTypeMember"
    [required]="required">
  </lc-form-field-relation>

  <lc-form-field-relation-productfinderstoryitemview *ngSwitchCase="'relation-productfinderstoryitemview'"
    [disabled]="disabled"
    [editMode]="editMode"
    [label]="label"
    [(ngModel)]="value"
    (ngModelChange)="onChange(value)"
    [relationName]="relationName"
    [relationParentWebObject]="relationParentWebObject"
    [relationSolutionType]="relationSolutionType"
    [relationWebObject]="relationWebObject">
  </lc-form-field-relation-productfinderstoryitemview>

  <lc-form-field-time-span-hours *ngSwitchCase="'timespanhours'"
    [disabled]="disabled"
    [editMode]="editMode"
    [label]="label"
    [(ngModel)]="value"
    (ngModelChange)="onChange(value)">
  </lc-form-field-time-span-hours>

  <lc-form-field-time-span-seconds *ngSwitchCase="'timespanseconds'"
    [disabled]="disabled"
    [editMode]="editMode"
    [label]="label"
    [(ngModel)]="value"
    (ngModelChange)="onChange(value)">
  </lc-form-field-time-span-seconds>

  <lc-form-field-warehouse-location-article-type *ngSwitchCase="'warehouselocationarticletype'"
    [appendTo]="appendTo"
    [disabled]="disabled"
    [editMode]="editMode"
    [label]="label"
    [(ngModel)]="value"
    (ngModelChange)="onChange(value)">
  </lc-form-field-warehouse-location-article-type>

  <lc-form-field-work-schedule *ngSwitchCase="'workschedule'"
    [disabled]="disabled"
    [editMode]="editMode"
    [label]="label"
    [(ngModel)]="value"
    (ngModelChange)="onChange(value)">
  </lc-form-field-work-schedule>


  <div *ngSwitchDefault
    class="form-field flex-col"
    [class.invalid]="isTouched && invalid"
    style="row-gap: 6px"
    (click)="onTouched()">
    <div *ngIf="label && (type !== 'checkbox' || !editMode || checkboxForceEmptyLabel)"
      class="flex-row gap-1">
      <label class="label"
        [innerHTML]="type === 'checkbox' && checkboxForceEmptyLabel && editMode ? '&nbsp;' : (label || '&nbsp;')">
      </label>
      <sup *ngIf="required && type !== 'checkbox'" class="label">*</sup>

      <i *ngIf="labelIconRight"
        [ngClass]="labelIconRight"
        (click)="labelIconRightClick.emit($event)"
        [pTooltip]="labelIconRightTooltip">
      </i>

      <i *ngIf="labelIconRight2"
        [ngClass]="labelIconRight2"
        (click)="labelIconRight2Click.emit($event)"
        [pTooltip]="labelIconRight2Tooltip">
      </i>
    </div>

    <div *ngIf="!editMode || type === 'link' || type === 'relation'"
      class="value">
      <div class="flex-row middle gap-1">
        <i *ngIf="iconLeft"
          [ngClass]="iconLeft">
        </i>

        <div *ngIf="type !== 'link'"
          [class.copy-on-hover]="allowCopyOnHover && (dateValue != null || readonlyValue != null || value != null)"
          (mousedown)="copyToClipboardMouseDown($event)"
          (click)="copyToClipboardClick($event)"
          [innerHTML]="dateValue ? (dateValue | dfnsFormat: (type === 'date' ? 'yyyy-MM-dd' : 'yyyy-MM-dd HH:mm'))
          : readonlyValue != null ? readonlyValue
          : value != null ? value
          : noValueHtml"
          [pTooltip]="dateValue ? '' : readonlyValue != null && readonlyValue?.length > 50 ? readonlyValue : value != null && value?.length > 50 ? value : ''"
          tooltipPosition="bottom">
        </div>
        <a *ngIf="type === 'link'"
          (click)="valueLinkClick.emit()"
          [innerHTML]="dateValue ? (dateValue | dfnsFormat: (type === 'date' ? 'yyyy-MM-dd' : 'yyyy-MM-dd HH:mm')) : readonlyValue != null ? readonlyValue : value != null ? value : noValueHtml"
          [pTooltip]="dateValue ? '' : readonlyValue != null ? readonlyValue : value != null ? value : ''"
          tooltipPosition="bottom">
        </a>

        <i *ngIf="iconRight"
          [ngClass]="iconRight"
          (click)="iconRightClick.emit()">
        </i>
      </div>
    </div>

    <div *ngIf="editMode && type !== 'link'">
      <p-checkbox *ngIf="type === 'checkbox'"
        #inputElement
        binary="true"
        [disabled]="disabled"
        [(ngModel)]="value"
        (ngModelChange)="onChange(value)"
        [label]="label">
      </p-checkbox>

      <p-triStateCheckbox *ngIf="type === 'checkbox3'"
        [disabled]="disabled"
        [(ngModel)]="value"
        (ngModelChange)="onChange(value)"
        [label]="value === true ? ('Yes' | translate) : value === false ? ('No' | translate) : ('Not Set' | translate)">
      </p-triStateCheckbox>

      <p-inputSwitch *ngIf="type === 'toggle-switch'"
        [disabled]="disabled"
        [(ngModel)]="value"
        (ngModelChange)="onChange(value)">
      </p-inputSwitch>

      <p-calendar *ngIf="type === 'date' || type === 'datetime'"
        [appendTo]="appendTo"
        styleClass="calendar"
        dateFormat="yy-mm-dd"
        [disabled]="disabled"
        [disabledDates]="calendarDisabledDates"
        hourFormat="24"
        [inline]="calendarInline"
        [maxDate]="maxDate"
        [minDate]="minDate"
        [monthNavigator]="true"
        [(ngModel)]="dateValue"
        (onInput)="updateValueWithDate(dateValue, false, $event)"
        (onClose)="updateValueWithDate(dateValue)"
        [selectionMode]="calendarSelectionMode || 'single'"
        [showTime]="type === 'datetime'"
        [view]="calendarView || 'date'"
        [yearNavigator]="true"
        [yearRange]="calendarYearRange">
      </p-calendar>
      <i *ngIf="editMode && !disabled && (type === 'date' || type === 'datetime')"
        class="calendar-icon link far fa-calendar-alt">
      </i>

      <p-calendar *ngIf="type === 'time'"
        [appendTo]="appendTo"
        styleClass="calendar"
        [disabled]="disabled"
        hourFormat="24"
        [inline]="calendarInline"
        [(ngModel)]="timeValue"
        (onInput)="updateValueWithTime(timeValue)"
        (onSelect)="updateValueWithTime(timeValue)"
        [timeOnly]="true">
      </p-calendar>

      <p-dropdown *ngIf="type === 'dropdown'"
        #inputElement
        [appendTo]="appendTo"
        styleClass="dropdown"
        panelStyleClass="dropdown-panel ag-custom-component-popup"
        [autoDisplayFirst]="false"
        [disabled]="disabled"
        [filter]="(!group && (options || []).length > 7) || (group && (options || []).length && (options[0].items || []).length > 4)"
        filterBy="label"
        [group]="group"
        [loading]="!options"
        [optionLabel]="optionLabel"
        [options]="options"
        [placeholder]="!options ? ('Loading...' | translate) : placeholder"
        [(ngModel)]="value"
        (ngModelChange)="onChange(value)"
        [required]="required"
        [showClear]="dropdownShowClear">
        <ng-template let-item pTemplate="selectedItem">
          <div class="flex-row middle">
            <div *ngIf="item.icon" [ngClass]="item.icon"></div>

            <div class="dropdown-label">
              {{ optionLabel ? (item.value ? (item.value | lcDeepFind:optionLabel) : (item | lcDeepFind:optionLabel)) : item.label }}
            </div>
          </div>
        </ng-template>

        <ng-template let-item pTemplate="item">
          <div class="flex-row middle"
            style="min-height: 2rem"
            [style.padding-left]="group ? '1rem': '0'">
            <div *ngIf="item.icon" [ngClass]="item.icon"></div>

            <div [class.ellipsis]="item.title || item.description">
              {{ optionLabel ? (item.value ? (item.value | lcDeepFind:optionLabel) : (item | lcDeepFind:optionLabel)) : item.label }}
            </div>
          </div>

          <div style="color: gray; line-height: 1; font-size: .8rem;"
            [innerHTML]="item.title || item.description || ''">
          </div>
        </ng-template>
      </p-dropdown>

      <p-multiSelect *ngIf="type === 'multi-select'"
        [appendTo]="appendTo"
        [disabled]="disabled"
        [filter]="(options || []).length > 7"
        filterBy="label"
        [group]="group"
        [(ngModel)]="value"
        (ngModelChange)="onChange(value)"
        [optionLabel]="optionLabel"
        [options]="options"
        [selectedItemsLabel]="selectedItemsLabel || ('{0} ' + ('Items selected' | translate))"
        [showHeader]="showMultiSelectHeader || (options || []).length > 7"
        [showToggleAll]="showMultiSelectHeader || (options || []).length > 7"
        [placeholder]="placeholder || ('Choose' | translate)">
      </p-multiSelect>

      <p-multiSelect *ngIf="type === 'multi-select-flags'"
        [appendTo]="appendTo"
        [disabled]="disabled"
        [filter]="false"
        filterBy="label"
        [group]="group"
        [(ngModel)]="multiSelectFlagsValue"
        (ngModelChange)="setValueFromMultiSelectFlagsValue($event)"
        [options]="options"
        [optionLabel]="optionLabel"
        [selectedItemsLabel]="selectedItemsLabel || '{0} ' + ('Items selected' | translate)"
        [showHeader]="showMultiSelectHeader || (options || []).length > 7"
        [showToggleAll]="showMultiSelectHeader || (options || []).length > 7"
        [placeholder]="'None' | translate">
      </p-multiSelect>

      <p-editor *ngIf="type === 'richtext' && isQuillLoaded"
        #inputElement
        [style]="{ height: '320px' }"
        [disabled]="disabled"
        [name]="name"
        [(ngModel)]="value"
        (ngModelChange)="onChange(value)">
        <p-header>
          <span class="ql-formats">
              <select class="ql-header">
                <option value="1">Heading</option>
                <option value="2">Subheading</option>
                <option selected="">Normal</option>
              </select>
              <select class="ql-font">
                <option selected="">Sans Serif</option>
                <option value="serif">Serif</option>
                <option value="monospace">Monospace</option>
              </select>
          </span>
          <span class="ql-formats">
              <button aria-label="Bold" class="ql-bold" type="button"><svg viewBox="0 0 18 18"> <path class="ql-stroke" d="M5,4H9.5A2.5,2.5,0,0,1,12,6.5v0A2.5,2.5,0,0,1,9.5,9H5A0,0,0,0,1,5,9V4A0,0,0,0,1,5,4Z"></path> <path class="ql-stroke" d="M5,9h5.5A2.5,2.5,0,0,1,13,11.5v0A2.5,2.5,0,0,1,10.5,14H5a0,0,0,0,1,0,0V9A0,0,0,0,1,5,9Z"></path> </svg></button>
              <button aria-label="Italic" class="ql-italic" type="button"><svg viewBox="0 0 18 18"> <line class="ql-stroke" x1="7" x2="13" y1="4" y2="4"></line> <line class="ql-stroke" x1="5" x2="11" y1="14" y2="14"></line> <line class="ql-stroke" x1="8" x2="10" y1="14" y2="4"></line> </svg></button>
              <button aria-label="Underline" class="ql-underline" type="button"><svg viewBox="0 0 18 18"> <path class="ql-stroke" d="M5,3V9a4.012,4.012,0,0,0,4,4H9a4.012,4.012,0,0,0,4-4V3"></path> <rect class="ql-fill" height="1" rx="0.5" ry="0.5" width="12" x="3" y="15"></rect> </svg></button>
          </span>
          <span class="ql-formats">
              <select class="ql-color"><option selected="selected"></option><option value="#e60000"></option><option value="#ff9900"></option><option value="#ffff00"></option><option value="#008a00"></option><option value="#0066cc"></option><option value="#9933ff"></option><option value="#ffffff"></option><option value="#facccc"></option><option value="#ffebcc"></option><option value="#ffffcc"></option><option value="#cce8cc"></option><option value="#cce0f5"></option><option value="#ebd6ff"></option><option value="#bbbbbb"></option><option value="#f06666"></option><option value="#ffc266"></option><option value="#ffff66"></option><option value="#66b966"></option><option value="#66a3e0"></option><option value="#c285ff"></option><option value="#888888"></option><option value="#a10000"></option><option value="#b26b00"></option><option value="#b2b200"></option><option value="#006100"></option><option value="#0047b2"></option><option value="#6b24b2"></option><option value="#444444"></option><option value="#5c0000"></option><option value="#663d00"></option><option value="#666600"></option><option value="#003700"></option><option value="#002966"></option><option value="#3d1466"></option></select>
              <select class="ql-background"><option value="#000000"></option><option value="#e60000"></option><option value="#ff9900"></option><option value="#ffff00"></option><option value="#008a00"></option><option value="#0066cc"></option><option value="#9933ff"></option><option selected="selected"></option><option value="#facccc"></option><option value="#ffebcc"></option><option value="#ffffcc"></option><option value="#cce8cc"></option><option value="#cce0f5"></option><option value="#ebd6ff"></option><option value="#bbbbbb"></option><option value="#f06666"></option><option value="#ffc266"></option><option value="#ffff66"></option><option value="#66b966"></option><option value="#66a3e0"></option><option value="#c285ff"></option><option value="#888888"></option><option value="#a10000"></option><option value="#b26b00"></option><option value="#b2b200"></option><option value="#006100"></option><option value="#0047b2"></option><option value="#6b24b2"></option><option value="#444444"></option><option value="#5c0000"></option><option value="#663d00"></option><option value="#666600"></option><option value="#003700"></option><option value="#002966"></option><option value="#3d1466"></option></select>
          </span>
          <span class="ql-formats">
              <button value="ordered" aria-label="Ordered List" class="ql-list" type="button"><svg viewBox="0 0 18 18"> <line class="ql-stroke" x1="7" x2="15" y1="4" y2="4"></line> <line class="ql-stroke" x1="7" x2="15" y1="9" y2="9"></line> <line class="ql-stroke" x1="7" x2="15" y1="14" y2="14"></line> <line class="ql-stroke ql-thin" x1="2.5" x2="4.5" y1="5.5" y2="5.5"></line> <path class="ql-fill" d="M3.5,6A0.5,0.5,0,0,1,3,5.5V3.085l-0.276.138A0.5,0.5,0,0,1,2.053,3c-0.124-.247-0.023-0.324.224-0.447l1-.5A0.5,0.5,0,0,1,4,2.5v3A0.5,0.5,0,0,1,3.5,6Z"></path> <path class="ql-stroke ql-thin" d="M4.5,10.5h-2c0-.234,1.85-1.076,1.85-2.234A0.959,0.959,0,0,0,2.5,8.156"></path> <path class="ql-stroke ql-thin" d="M2.5,14.846a0.959,0.959,0,0,0,1.85-.109A0.7,0.7,0,0,0,3.75,14a0.688,0.688,0,0,0,.6-0.736,0.959,0.959,0,0,0-1.85-.109"></path> </svg></button>
              <button value="bullet" aria-label="Unordered List" class="ql-list" type="button"><svg viewBox="0 0 18 18"> <line class="ql-stroke" x1="6" x2="15" y1="4" y2="4"></line> <line class="ql-stroke" x1="6" x2="15" y1="9" y2="9"></line> <line class="ql-stroke" x1="6" x2="15" y1="14" y2="14"></line> <line class="ql-stroke" x1="3" x2="3" y1="4" y2="4"></line> <line class="ql-stroke" x1="3" x2="3" y1="9" y2="9"></line> <line class="ql-stroke" x1="3" x2="3" y1="14" y2="14"></line> </svg></button>
              <select class="ql-align">
                  <option selected=""></option>
                  <option value="center"></option>
                  <option value="right"></option>
                  <option value="justify"></option>
              </select>
          </span>
          <span class="ql-formats">
              <button aria-label="Insert Link" class="ql-link" type="button"></button>
              <button aria-label="Insert Image" class="ql-image" type="button"></button>
              <button aria-label="Insert Video" class="ql-video" type="button"></button>
              <button aria-label="Insert Code Block" class="ql-code-block" type="button"></button>
              <button *ngIf="editorToolbarWidgetLinkClick.observers?.length" aria-label="Insert Widget Link" class="ml-widget-link" type="button" (click)="editorToolbarWidgetLinkClick.emit(editor)">Widget Link</button>
          </span>
          <span class="ql-formats">
              <button aria-label="Remove Styles" class="ql-clean" type="button"></button>
          </span>
        </p-header>
      </p-editor>

      <p-rating *ngIf="type === 'rating'"
        [cancel]="false"
        [disabled]="disabled"
        [(ngModel)]="value"
        (ngModelChange)="onChange(value)"
        [readonly]="readOnly"
        [required]="required"
        [stars]="ratingStars || 5">
      </p-rating>

      <p-selectButton *ngIf="type === 'select-button'"
        [options]="options"
        [(ngModel)]="value"
        (ngModelChange)="onChange(value)"
        [required]="required">
      </p-selectButton>

      <textarea *ngIf="type === 'textarea'"
        #inputElement
        pInputText
        [autofocus]="autoFocus"
        class="input"
        [disabled]="disabled"
        [minlength]="minLength"
        [maxlength]="maxLength"
        [(ngModel)]="value"
        (ngModelChange)="onChange(value)"
        [placeholder]="placeholder ? placeholder : ''"
        [readonly]="readOnly"
        [rows]="textAreaRows || 3">
      </textarea>

      <div *ngIf="['checkbox', 'checkbox3', 'date', 'datetime', 'dropdown', 'html', 'link', 'multi-select', 'multi-select-flags', 'rating', 'richtext', 'select-button', 'textarea', 'time', 'toggle-switch'].indexOf(type) < 0"
        class="p-inputgroup">
        <span *ngIf="inputAddonLeft" class="p-inputgroup-addon">{{ inputAddonLeft }}</span>

        <p-inputNumber *ngIf="type === 'number'"
          #inputElement
          class="input"
          [autofocus]="autoFocus"
          (blur)="onBlur.emit($event)"
          [disabled]="disabled"
          (focus)="textSelectOnFocus ? (inputElement?.nativeElement || inputElement).select($event) : null"
          [mask]="textMask"
          [max]="maxNumber"
          [maxlength]="maxLength"
          [min]="minNumber"
          [minlength]="minLength"
          inputmode="decimal"
          [(ngModel)]="value"
          (ngModelChange)="onChange($event)"
          [placeholder]="placeholder || ''"
          [required]="required"
          [step]="step || '.1'">
        </p-inputNumber>

        <input *ngIf="type !== 'number'"
          #inputElement
          pInputText
          class="input"
          [autofocus]="autoFocus"
          [disabled]="disabled"
          (blur)="onBlur.emit($event)"
          (focus)="textSelectOnFocus ? (inputElement?.nativeElement || inputElement).select($event) : null"
          [mask]="textMask"
          [maxlength]="maxLength"
          [minlength]="minLength"
          [(ngModel)]="value"
          (ngModelChange)="type === 'msisdn' ? updateValueWithPhoneNumber($event) : onChange($event)"
          [pattern]="pattern"
          [placeholder]="placeholder || ''"
          [required]="required"
          [type]="['password', 'textarea', 'text'].indexOf(type) >= 0 ? type : 'text'" />

        <span *ngIf="inputAddonRight" class="p-inputgroup-addon">{{ inputAddonRight }}</span>

        <p-colorPicker *ngIf="type === 'color'"
          appendTo="body"
          [format]="colorFormat"
          [(ngModel)]="value"
          (ngModelChange)="onChange($event)">
        </p-colorPicker>
      </div>
    </div>
  </div>
</div>
