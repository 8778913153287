import { GuidUtils } from 'app/shared/utils';
import { DictString, SolutionType } from '../../../shared/models';
import { FlowFieldsObject } from '../flow/flow-fields-object.model';
import { VariableToolboxGroupItem } from '../variable-toolbox/variable-toolbox-group-item.model';
import { Field, FieldType } from './field.model';



export class RelationField extends Field {

  childGuidId: string;
  childGuidIds: string[];
  dataName: string;
  isList: boolean;
  relationName: string;
  typeGuidId: string;
  fieldsObjectTypeGuidId: string;

  $fieldsObjects: FlowFieldsObject[];
  $fieldsObjectsFieldMap: DictString<Field>

  constructor(field?: Field) {
    super(field);
    this.webFieldType = FieldType.Relation;
  }

  getValue(): string {
    return this.childGuidId === GuidUtils.emptyGuid() ? '' : this.childGuidId;
  }

  getVariableValidHint(solutionTypes: SolutionType[]): string {
    return 'Not supported supported by this field.';
  }

  isVariableValidHere(v: VariableToolboxGroupItem, solutionTypes: SolutionType[]): string {
    return 'Not supported supported by this field.';
  }

}
