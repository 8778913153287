import { ICellEditorAngularComp } from 'ag-grid-angular';
import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { CaseUtils } from 'app/shared/utils/case.utils';
import { GuidUtils } from 'app/shared/utils/guid.utils';
import { SelectItem } from 'primeng/api';
import { GridCellEditorFormField } from '../grid-celleditor-form-field/grid-celleditor-form-field.component';
import { GridCellRendererText } from '../grid-cellrenderer-text/grid-cellrenderer-text.component';
import { BaseGridComponent } from '../../base/base-grid.component';
import { SessionService } from 'app/shared/services/app';
import { WebCenterTypeMemberSpecialValue } from 'app/center-v2/shared/models/web-base/web-center-type/web-center-type-member-special-value.model';


@Component({
  selector: 'lc-grid-celleditor-center-type-member-special-values',
  templateUrl: 'grid-celleditor-center-type-member-special-values.component.html',
  styleUrls: ['grid-celleditor-center-type-member-special-values.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GridCellEditorCenterTypeMemberSpecialValues extends BaseGridComponent implements ICellEditorAngularComp, AfterViewInit {

  readonly ignoreColumns: string[] = ['guidId'];
  readonly sortByColumn = '';

  params: any;
  label: string;
  originalValue: WebCenterTypeMemberSpecialValue[];
  specialValues: WebCenterTypeMemberSpecialValue[];

  constructor(
    cdr: ChangeDetectorRef,
    sessionService: SessionService,
    private translateService: TranslateService,
  ) {
    super(cdr, sessionService);

    this.label = this.translateService.instant('Special Values');
    this.gridCols = this.buildGridCols();
  }

  ngAfterViewInit() {

  }

  agInit(params: any): void {
    this.params = params;

    if (
      !this.params?.data ||
      !this.params.data[this.params.colDef?.field]
    ) {
      this.stopEditing();
      return;
    }

    this.specialValues = this.params.data[this.params.colDef.field];
    this.originalValue = JSON.parse(JSON.stringify(this.specialValues));

    this.refresh();
  }

  refresh(options?: any) {
    if (this.specialValues) {
      this.gridRows = this.specialValues || [];

      this.cdr.markForCheck();
    }
  }

  private buildGridCols(): any[] {
    const gridCols: any[] = [];

    for (const key of Object.keys(new WebCenterTypeMemberSpecialValue())) {
      if (this.ignoreColumns.indexOf(key) >= 0) continue;

      let editorOptions: SelectItem[] = [];
      let editorType: string = 'text';
      switch (key) {
        case 'readOnly':
          editorType = 'checkbox';
          break;
      }
      gridCols.push({
        headerName: this.translateService.instant(CaseUtils.capitalizeFirstLetter(key)),
        field: key,
        sort: key === this.sortByColumn ? 'asc' : undefined,
        cellEditor: GridCellEditorFormField,
        cellEditorParams: {
          options: editorOptions,
          type: editorType,
        },
        cellRenderer: GridCellRendererText,
        cellRendererParams: {
          filter: () => { return this.gridQuickFilter; },
          isDate: key.toLowerCase().indexOf('tick') >= 0,
        },
        editable: key !== this.sortByColumn,
        minWidth: 120,
      });
    }

    return gridCols;
  }

  addNew() {
    const newCenterTypeMemberSpecialValue = new WebCenterTypeMemberSpecialValue({
      guidId: GuidUtils.new(),
    });
    this.specialValues.push(newCenterTypeMemberSpecialValue);

    const rowDataTransaction = {
      add: [newCenterTypeMemberSpecialValue],
    };
    this.grid.agGrid.api.applyTransactionAsync(rowDataTransaction);
    this.cdr.markForCheck();
  }

  getValue(): any {
    return this.params?.value; // this.specialValues;
  }

  isPopup(): boolean {
    return true;
  }

  stopEditing() {
    this.params.api.stopEditing(false);
  }

}


