import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { WorkScheduleDay } from 'app/center-v2/shared/models/web-center-types/web-work-schedule.model';
import { GenericService } from 'app/center-v2/shared/services/generic.service';
import { ConfirmDialog } from 'app/shared/dialogs/confirm/confirm.dialog';
import { BaseDialog } from 'app/shared/dialogs/base/base.dialog';
import { SessionService } from 'app/shared/services/app';
import { NotificationService } from 'app/shared/services/app/notification.service';
import { DateTimeIndexUtils } from 'app/shared/utils/date-time-index.utils';
import { format } from 'date-fns';



@Component({
  selector: 'lc-edit-work-schedule-day-dialog',
  templateUrl: './edit-work-schedule-day.dialog.html',
  styleUrls: ['./edit-work-schedule-day.dialog.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EditWorkScheduleDayDialog extends BaseDialog {

  @ViewChild(ConfirmDialog, { static: true }) confirmDialog: BaseDialog;

  data: {
    date: Date;
    workScheduleDay: WorkScheduleDay;
  }

  dayStartTime: Date;
  dayEndTime: Date;

  constructor(
    cdr: ChangeDetectorRef,
    private genericService: GenericService,
    private notificationService: NotificationService,
    sessionService: SessionService,
    private translateService: TranslateService,
  ) {
    super(cdr, sessionService);

  }

  init(data: any) {
    if (!this.data) return;
    this.data.workScheduleDay = JSON.parse(JSON.stringify(this.data.workScheduleDay));

    this.dayStartTime = this.data.workScheduleDay.dayStartTime ? this.getTimeValue(this.data.workScheduleDay.dayStartTime)
    : DateTimeIndexUtils.decodeSecondsIndexToDate(this.data.date, this.data.workScheduleDay.dayStartTimeIndex);
    this.dayEndTime = this.data.workScheduleDay.dayEndTime ? this.getTimeValue(this.data.workScheduleDay.dayEndTime)
    : DateTimeIndexUtils.decodeSecondsIndexToDate(this.data.date, this.data.workScheduleDay.dayEndTimeIndex);

    this.refresh();
  }

  refresh() {

  }

  confirm() {
    delete this.data.workScheduleDay.dayStartTime;
    delete this.data.workScheduleDay.dayEndTime;
    this.data.workScheduleDay.dayStartTimeIndex = this.dayStartTime ? DateTimeIndexUtils.encodeDateToSecondsIndex(this.dayStartTime) : null;
    this.data.workScheduleDay.dayEndTimeIndex = this.dayEndTime ? DateTimeIndexUtils.encodeDateToSecondsIndex(this.dayEndTime) : null;
    this.close(this.data.workScheduleDay);
  }

  delete() {
    this.confirmDialog.show({
      title: this.translateService.instant('Delete Item'),
      message: this.translateService.instant('Are you sure you want to delete this item?'),
    });
    this.confirmDialog.onClose = (result: any) => {
      if (result) {
        (this.data.workScheduleDay as any).$deleted = true;
        this.close(this.data.workScheduleDay);
      }
    };
  }

  private getTimeValue(value: Date | string | number | undefined): Date | undefined {
    if (!value) return undefined;

    if (value instanceof Date) {
      return value;
    } else if (typeof value === 'string') {
      if (value.indexOf('T') >= 0) {
        return new Date(value);
      }
      if (value.length === 4) value = value.slice(0, 2) + ':' + value.slice(2);

      return new Date((new Date()).toISOString().substring(0, 11) + (value || '00:00:00'));
    } else if (typeof value === 'number') {
      return new Date((new Date()).toISOString().substring(0, 11) + DateTimeIndexUtils.decodeSecondsIndexToTimeString(value));
    }
  }

}

