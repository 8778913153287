<div class="grid-field flex-col">
  <div *ngIf="label || !hideSearch || !hideExportButton || actionButtonIcon || actionButtonLabel"
    class="flex-row gap-1 middle">
    <label class="label"
      [innerHTML]="label">
    </label>

    <div class="flex-1"></div>

    <lc-search-input *ngIf="!hideSearch"
      [ngModel]="gridQuickFilter"
      (ngModelChange)="gridQuickFilterChanged.next($event);">
    </lc-search-input>

    <lc-button *ngIf="!hideExportButton"
      (click)="exportToCSV()"
      [label]="'Export' | translate"
      type="secondary">
    </lc-button>

    <lc-button *ngIf="actionButtonIcon || actionButtonLabel"
      [async]="true"
      (click)="actionButtonClick.emit($event)"
      [disabled]="actionButtonDisabled"
      [icon]="actionButtonIcon"
      [label]="actionButtonLabel"
      [type]="actionButtonType">
    </lc-button>
  </div>

  <lc-grid *ngIf="refreshCallback.observers.length"
    [style.height]="gridHeight || '100%'"
    [autoGroupColumnDef]="autoGroupColumnDef"
    [autoHeight]="autoHeight"
    [autoSizeColumns]="autoSizeColumns"
    [columns]="gridCols"
    [getDataPath]="getDataPath"
    [getGridReady]="getGridReady"
    [getRowHeight]="getRowHeight"
    [getRowId]="getRowId"
    [getRowStyle]="getRowStyle"
    [getServerSideGroupKey]="getServerSideGroupKey"
    [groupDefaultExpanded]="groupDefaultExpanded"
    [isRowSelectable]="isRowSelectable"
    [isServerSideGroup]="isServerSideGroup"
    [lastUpdateDate]="lastUpdateDate"
    [lastUpdateFailed]="lastUpdateFailed"
    [overlayNoRowsTemplate]="overlayNoRowsTemplate"
    [pinnedBottomRows]="pinnedBottomRows"
    [quickFilter]="gridQuickFilter"
    (refreshClick)="refreshCallback.emit()"
    (rowClicked)="rowClicked.emit($event)"
    (rowDoubleClicked)="rowDoubleClicked.emit($event)"
    (rowDragEnd)="rowDragEnd.next($event)"
    [rowDragManaged]="rowDragManaged"
    [rows]="gridRows"
    [rowSelection]="rowSelection"
    (selectionChanged)="selectionChanged($event)"
    [serverSideDataSource]="serverSideDataSource"
    [theme]="theme"
    [treeData]="treeData">
  </lc-grid>
  <lc-grid *ngIf="!refreshCallback.observers.length"
    [style.height]="gridHeight || '100%'"
    [autoGroupColumnDef]="autoGroupColumnDef"
    [autoHeight]="autoHeight"
    [autoSizeColumns]="autoSizeColumns"
    [disableInitialSort]="disableInitialSort"
    [columns]="gridCols"
    [getDataPath]="getDataPath"
    [getGridReady]="getGridReady"
    [getRowHeight]="getRowHeight"
    [getRowId]="getRowId"
    [getRowStyle]="getRowStyle"
    [getServerSideGroupKey]="getServerSideGroupKey"
    [groupDefaultExpanded]="groupDefaultExpanded"
    [isServerSideGroup]="isServerSideGroup"
    [lastUpdateDate]="lastUpdateDate"
    [lastUpdateFailed]="lastUpdateFailed"
    [overlayNoRowsTemplate]="overlayNoRowsTemplate"
    [pinnedBottomRows]="pinnedBottomRows"
    [quickFilter]="gridQuickFilter"
    (rowClicked)="rowClicked.emit($event)"
    (rowDoubleClicked)="rowDoubleClicked.emit($event)"
    (rowDragEnd)="rowDragEnd.next($event)"
    [rowDragManaged]="rowDragManaged"
    [rows]="gridRows"
    [rowSelection]="rowSelection"
    (selectionChanged)="selectionChanged($event)"
    [serverSideDataSource]="serverSideDataSource"
    [theme]="theme"
    [treeData]="treeData">
  </lc-grid>
</div>
