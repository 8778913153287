import { SolutionType } from 'app/shared/models';
import { SelectItem } from 'primeng/api';
import { VariableToolboxGroupItem } from '../variable-toolbox/variable-toolbox-group-item.model';
import { Field, FieldType } from './field.model';
import { SpecialValue } from './special-value-enums';

export class PropertyField extends Field {

  memberName: string;
  value: any;
  specialValue: SpecialValue;

  $options: SelectItem[];

  constructor(field?: any) {
    super(field);
    this.webFieldType = FieldType.Property;
  }

  getValue(): any {
    return this.value;
  }

  getValidation(): any {
    switch (this.specialValue) {
      case SpecialValue.ZapierApiItemName:
        return {
          pattern: '[a-zA-Z]*',
          message: 'Can only contain uppercase and / or lowercase letters (A-Z or a-z).'
        };
      default:
        return undefined;
    }
  }

  getVariableValidHint(solutionTypes: SolutionType[]): string {
    return 'Not supported supported by this field.';
  }

  isVariableValidHere(v: VariableToolboxGroupItem, solutionTypes: SolutionType[]): string {
    return 'Not supported supported by this field.';
  }

}
