import { WebCenterTypeCollectionWeb2Access } from './web-center-type-collection-web-access.enum';

// I'm "initializing" all fields to be able to use "reflection" and get all class fields
export class WebCenterTypeCollection {

  typeGuidId?: string = undefined;
  version?: number = undefined;
  typeCollectionGuidId?: string = undefined;
  collectionName?: string = undefined;
  web2Collection?: boolean = undefined;
  web2Access?: WebCenterTypeCollectionWeb2Access = undefined;

  constructor(item?: Partial<WebCenterTypeCollection>) {
    Object.assign(this, item);
  }
}
