import { WebCenterTypeMemberSpecialValueType } from './web-center-type-member-special-value-type.enum';
import { WebCenterTypeMemberSpecialValue } from './web-center-type-member-special-value.model';
import { WebCenterTypeRelationWeb2Access } from './web-center-type-relation-web-access.enum';


// I'm "initializing" all fields to be able to use "reflection" and get all class fields
export class WebCenterTypeRelationMember {

  guidId?: string = undefined;
  version?: number = undefined;
  memberId?: number = undefined;
  name?: string = undefined;
  dataName?: string = undefined;
  nativeType?: string = undefined;
  nativeSize?: number = undefined;
  specialMemberType?: string = undefined;
  specialValues?: WebCenterTypeMemberSpecialValue[] = undefined;
  specialValuesType?: WebCenterTypeMemberSpecialValueType = undefined;
  web2Member?: boolean = undefined;
  web2Access?: WebCenterTypeRelationWeb2Access = undefined;


  constructor(item?: Partial<WebCenterTypeRelationMember>) {
    Object.assign(this, item);

    this.specialValues = (this.specialValues || []).map(x => new WebCenterTypeMemberSpecialValue(x));
  }
}
