import { Pipe, PipeTransform } from '@angular/core';
import { format } from 'date-fns';
import { DateTimeIndexUtils } from '../utils/date-time-index.utils';


@Pipe({
  name: 'lcDateTime',
  standalone: true,
})
export class DateTimePipe implements PipeTransform {

  private readonly patrikEpoch = 1451606400000; // Jan 1, 2016

  transform(dt: Date | string | number): string {
    if (!dt) return '';
    if (dt instanceof Date && isNaN(dt.getTime())) return '';

    if (dt instanceof Date) {
      return format(dt, 'yyyy-MM-dd HH:mm');
    } else if (typeof dt === 'string') {
      return format(new Date(dt), 'yyyy-MM-dd HH:mm');
    } else {
      try {
        if (dt > 100000000000000) { // if whatever value is big enough to be in nanoseconds (C#), it's a patrikTick so we convert it
          return format(new Date(this.patrikEpoch + (dt / 10000)), 'yyyy-MM-dd HH:mm');
        } else {
          return format(new Date(dt), 'yyyy-MM-dd HH:mm');
        }
      } catch (error) {
        return format(DateTimeIndexUtils.decodeDayIndexToDate(dt), 'yyyy-MM-dd HH:mm');
      }
    }
  }
}
