<div class="input-search">
  <input #input
    pInputText
    type="text"
    name="filter"
    [(ngModel)]="value"
    (ngModelChange)="onChange(value)"
    [placeholder]="label || (('Search' | translate) + '...')" />
    <a *ngIf="!value"
      class="small-icon no-action fas fa-magnifying-glass"
      [pTooltip]="'Type to search' | translate"
      tooltipPosition="top"
      (click)="null">
    </a>
    <a *ngIf="!!value"
      class="small-icon fas fa-xmark"
      [pTooltip]="'Reset search filter' | translate"
      tooltipPosition="top"
      (click)="reset()">
    </a>
</div>
