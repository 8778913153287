<div class="grid-field flex-col">
  <div class="flex-row middle gap-1">
    <label class="label"
      [innerHTML]="label">
    </label>

    <div class="flex-1"></div>

    <lc-search-input
      [ngModel]="gridQuickFilter"
      (ngModelChange)="gridQuickFilterChanged.next($event);">
    </lc-search-input>

    <lc-button
      (click)="exportToCSV()"
      [label]="'Export to CSV' | translate"
      type="secondary">
    </lc-button>

    <lc-button
      (click)="addNew()"
      icon="fas fa-plus"
      [label]="'Add New' | translate"
      type="primary">
    </lc-button>
  </div>

  <lc-grid class="flex-1"
    [style.height]="gridHeight || '100%'"
    [columns]="gridCols"
    [lastUpdateDate]="lastUpdateDate"
    [quickFilter]="gridQuickFilter"
    (refreshClick)="refresh()"
    [rows]="gridRows">
  </lc-grid>
</div>