import { ChangeDetectorRef, Component, OnDestroy, ViewChild } from '@angular/core';
import { SessionService } from 'app/shared/services/app';
import { Subject, Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { GridComponent } from '../grid';
import { BaseComponentV2 } from './base-v2.component';



@Component({
  host: {'grid-viewer': 'true' },
  template: '<div></div>',
})
export abstract class BaseGridViewerComponent extends BaseComponentV2 implements OnDestroy {

  @ViewChild(GridComponent, { static: false }) gridComponent: GridComponent;

  get grid(): GridComponent {
    return this.gridComponent;
  }
  gridCols: any[];
  gridQuickFilter: string;
  gridQuickFilterChanged: Subject<string>;
  gridHeight: number;
  gridRows: any[];

  lastRequestTick: number;
  lastUpdateDate: Date;
  lastUpdateFailed: boolean;
  nextUpdateDate: Date;
  refreshSubscription: Subscription;
  refreshTimeout: any;

  constructor(
    cdr: ChangeDetectorRef,
    sessionService: SessionService,
  ) {
    super(cdr, sessionService);

    this.gridQuickFilterChanged = new Subject();
    this.gridQuickFilterChanged.pipe(debounceTime(250))
    .subscribe((quickFilter: string) => {
      const quickFilterChanged = this.gridQuickFilter !== quickFilter;
      this.gridQuickFilter = quickFilter;
      this.filterRows(quickFilterChanged);
    });
  }

  ngOnDestroy() {
    super.ngOnDestroy();

    this.lastUpdateFailed = true;
    this.unsubscribeRefreshTimeout();
  }

  protected unsubscribeRefreshTimeout() {
    clearTimeout(this.refreshTimeout);

    if (this.refreshSubscription) {
      this.refreshSubscription.unsubscribe();
      this.refreshSubscription = null;
    }
  }

  protected filterRows(quickFilterChanged?: boolean) {
    if (!this.grid) return;

    this.grid.refresh();
  }

  exportToCSV() {
    if (!this.grid) return;

    this.grid.exportToCSV();
  }

}
