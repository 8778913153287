import { Tag } from '../../../shared/models/tag.model';
import { WorkspaceWidgetSetting } from './workspace-widget-setting.model';
import { WebObject } from './web-base/web-object.model';

export class WorkspaceWidget extends WebObject {

  static readonly WidgetTemplateMap = {
    '55e75b12-869f-477b-99ef-f78b18659831': 'active-solution',
    '90656b88-6404-4151-bf71-cd203c17b010': 'advanced-search',
    '983e3390-c7ce-4c3e-92d1-72c14b9caa0e': 'api2-object-access-admin',
    'f2e036e1-faec-4f17-a883-5b1d76b018bd': 'article-system-admin',
    'b8578e72-b245-48b9-bcc6-2a5fec0fe4af': 'attention',
    '5de5bf23-2b70-4674-95f5-6407c1921730': 'barcode-generator',
    '9ab1ee98-2144-4116-8a17-a8a0bb6f3e5b': 'base-system-article-import',
    '47d2756f-c5d9-4279-a12d-f2b3bbeceb8f': 'base-system-gs1-setup',
    '309c9107-d612-461a-a5c4-a489185a5751': 'center-type-admin',
    'ed424d7a-3f96-433f-beac-c0510794382e': 'checklist-admin',
    '12ec6420-4d80-4dba-b3d9-deba62f81a8c': 'custom-actions',
    '939de4b9-06dd-4ed2-b1c1-c6f0f84f307f': 'day-segments',
    'af7dfc08-b20d-4f60-a532-bac2550d2442': 'deliverect-setup',
    '4d40c647-5b1b-43dc-8d13-f672eb8684b5': 'device-overview',
    '4eb3e69b-648b-42f0-b367-3601b2f6183b': 'device-solution-set',
    '0f741226-f2e5-4291-8204-dd507cc5d212': 'device-solution-set-admin',
    '62432cad-b378-46d4-983b-bdd031303cad': 'device-studio',
    '6987c904-8b2b-4d61-8ca1-45112f909138': 'empty',
    '2d960a06-2aaa-407f-9c7b-9f15c32277cb': 'environment-texts-admin',
    '7c28d0f1-47be-4a52-9eeb-3492bf5bc72c': 'filter-object-admin',
    'effed7c7-cc58-41de-bd2a-83da419d37f7': 'forecast',
    'dfbdbe47-50f8-4357-8355-fa07f7dd105d': 'gps-devices',
    '11fb011c-6b0a-4e74-970d-b0bcf80409a5': 'inventory-order-review-tool',
    '726297ba-430b-4f56-b44d-8607ef644d2b': 'label-templates-admin',
    '65c0faaf-7805-46ba-a13d-069907157f99': 'label-templates-admin-v2',
    'f1a3b935-97a6-4178-a1e6-c59d72848ef0': 'new-devices',
    '37b34f91-fe4d-4c9f-8be9-b14dade1ef23': 'object-list',
    'bbd43501-4870-4cff-b689-e00391cfd514': 'object-view',
    '4cab5cff-2c03-4636-99d9-bf3718391976': 'order-manager',
    'f9336959-e59b-49d9-8197-dc11e428f519': 'order-system-admin',
    '099ed7c9-98db-45f4-909e-868c7b6f57c0': 'order-v2-manager',
    'afb83abf-d61d-4e64-becc-690c2f34ae04': 'order-v2-queue-assignment',
    'aef50272-5bde-417b-bbe0-1aff8a0243f2': 'order-v2-queue-handle',
    '646163a4-ea2f-4adb-8ac1-a4d7d083e3d2': 'mission-studio',
    '775d54cb-c922-4a25-828c-610d367b71e2': 'pick-system',
    '3eb0a8f1-3a8f-40c7-ae40-a2145fccda4d': 'pick-system-setup',
    '485eae17-5cce-4f86-8b2a-7ad9a136b300': 'pick-system-picklist-types',
    'eb01d201-6781-4ae2-a2c3-f470cae4b7fb': 'planner',
    '0639d493-7dae-4c55-afff-3ed328960939': 'plan-system-admin',
    '0f204071-5002-4f40-a327-62e0c072c71b': 'platform-report',
    '06029202-e26f-427f-b051-0be3673fa858': 'preem-orders',
    '2b6f4d0a-6589-4d8c-8eac-99f092af8074': 'pricelist-system-pdf-folders',
    '85eff122-ba53-4871-a186-2d95843930c3': 'production-planner',
    '6b244266-b0d7-4281-959f-fe28ea5336f7': 'production-template-planner',
    'd9c4036e-35f9-40bc-b701-af7f7ef9b18b': 'product-system-comparator',
    'b169e452-2c15-49d3-8c8f-d9670ad23c0d': 'product-system-families',
    'eb9a8f62-c4e4-49ab-a638-5a3874efda04': 'product-system-finder-admin',
    'e20e6b42-6dc7-47a5-9142-b0a0e4ebf6c2': 'product-system-sato-pretenders',
    '26cb9ff8-c022-43b5-94ab-c2917ac93c14': 'product-system-sato-searcher',
    'a0f139bc-538c-4b2f-83dd-539c2b81a511': 'products',
    'f8992822-628b-466a-85a6-a602c2d3747f': 'purchase-order-v2-process',
    '34153e35-dd65-429d-9dcf-24100c086111': 'queue-state',
    'af80d58b-23fd-4f26-9578-1232c869001f': 'register-tv-device',
    'dd3b0cd4-0dcf-4297-978a-f29f58fe4708': 'resources-folder',
    '1e12b0a4-8db0-49ee-92b7-97773aa972a6': 'schedulers-state',
    '88811242-3e9b-4387-a99c-6112f62ae9a5': 'sato-access-request',
    'b6d6fd20-ff75-4904-ad3b-89209cff791a': 'sato-aep-dm-package-builder',
    '9531c181-78aa-4b43-a988-fd9b2ac7b1e9': 'sato-iot-dashboard',
    '6be7a585-d072-40a5-9e00-87d299fc2145': 'sato-new-tender-partner',
    '8cb4bc1b-94f4-40ed-92a5-9f1b6f324460': 'sato-payperprint',
    'de489583-dcfe-402a-9727-9b57e348b55d': 'sato-payperprint-margins',
    '68eb458a-e75d-4416-8dc4-ebc82f5611be': 'servly-active-view',
    '71754307-e121-4e05-9d22-5811ea3c6a7d': 'servly-create-test-orders',
    '73d7ee06-418f-4a1a-ae65-909cadb8b0ca': 'servly-ifb',
    '4fd0e760-9780-48a7-94c6-6fddf1f9b0d8': 'servly-invoice-attest',
    'c4f1dcec-fdd7-4fbb-b784-ec857306c0f4': 'servly-material-planning',
    '8aae19b5-a8da-49c6-9903-dc9c65675668': 'servly-review-service-orders',
    'eaeeaa89-0387-4560-b174-42401e4567f4': 'servly-new-service',
    'd94c0dea-8e40-461d-a189-6ade7fe6f989': 'servly-planner',
    'da49756d-7b8b-40cc-b781-9f3ed6d835cd': 'route-overview',
    '7aea7ddc-a213-4132-a068-eb12177cc0f7': 'servly-route-review',
    'dbb40663-e326-4e3d-971b-794dec77fbcf': 'servly-survey-results',
    '5dd7d0c7-b78c-457e-a3f5-36aef6feba02': 'site-admin',
    '8862f9b8-622c-47c7-b1e5-183ff252f442': 'site-object-admin',
    '28eb37d9-ca19-40fa-976c-001ec2417011': 'site-structural-object-list',
    '71eb84c3-8150-47a7-80a0-baf533dcda3a': 'smart-image-admin',
    'ae07982a-e3a2-4edb-992c-b069b1a46a01': 'solution-data-admin',
    '870512bf-7336-4fb4-8d5b-bb5ad82b6d40': 'solution-event-system',
    '3f546bfb-4cfd-4e62-860c-3f32513bc66d': 'solution-profile-admin',
    'a0fb3fea-4ae8-4eb0-a080-02ef142a4fff': 'solution-profile-v1',
    'aa4490ca-c116-4a51-97f0-a1421dbccc5a': 'solution-status',
    '90bb5ba5-33ca-444b-9a60-8b07aaa2991a': 'structural-admin',
    '13da5889-fd3e-4c74-bf3b-f2247e3007c3': 'structured-objects',
    '45192abe-2be8-43c7-8efd-cfdcaeb9664b': 'system-import',
    'fb275ddc-f936-4fc2-9db2-c1cc50ffaef0': 'tanks',
    '2cf04ef9-223f-4b33-b533-67b5dbe94d0e': 'tank-editor',
    'b72ebb9f-8b71-441e-8b3d-1c877a7da597': 'tank-gauge',
    '481f92e0-6495-4dc7-aefd-5f73a37088f4': 'task-manager',
    'c8c14363-3a91-4cc4-acab-960996b6da80': 'task-planning',
    '4e60a850-2604-41a5-a948-6a5bc5f2d973': 'task-statistics',
    'dd4ebb28-aa4d-455d-b630-2d46ff7b2e2c': 'task-system-admin',
    '174f7b0d-e570-44c0-84d1-9ca3cfa53296': 'tasks',
    'bc092de9-9691-4169-9a42-f6b93b4634b8': 'telematic',
    'b26cf42a-f3d7-4e08-90ad-2787c9b8f228': 'test-label-print',
    'f0f1596c-be5a-439a-9416-e7bb50207b51': 'topfuel-orders',
    'afb126db-7eb9-4fef-ae75-fbc92e19e1ac': 'trigger-system-admin',

    '218651dc-7e10-4427-9602-8c4b2571571e': 'user-text',
    'd13d4e44-02e3-4547-b12c-36b80d39bf88': 'view-system',
    'f44fc5a8-e815-48c7-9d53-7469b3bfabe9': 'visma-net-integration',

    '77b89367-7eb8-4229-a81f-33d44a1ad84f': 'warehouse-admin',
    '0c6315ba-25b7-4d5d-b7fa-aae5884287ef': 'warehouse-setup',
    '517314b6-45de-40ae-8130-c2d867641c8b': 'webshop-store',
    '8f336ba0-bda9-453a-a64c-2a522ab6dbb7': 'web-device',
    '22275a3b-17fb-4a5b-911b-bea3dfbd6dce': 'web-device-group',
    '4f4e2aa3-7a18-4128-95b1-e4e5dbc4534c': 'weekly-consumption',
    '99136717-305a-4576-9fd1-eb15f6a92b63': 'weekly-index',

    '361acfd0-6f50-4482-9f5a-f17f55ed1c87': 'workspace-languages',
    'fd3d6b79-dfe6-4439-aa2f-65988602e457': 'workspace-navigator',
    'c111300b-4735-4c25-8fba-75ab9879f382': 'workspace-solution-types',

    '5e6fcbb1-5468-439c-9bd0-31bb38769fb8': 'work-schedule-admin',
    'fc40c914-a36f-461d-b8b1-6b2d08d638f3': 'work-schedule-overview',
    '135210d2-d12e-468c-804b-c89d23b15048': 'work-schedule-requests',
    '4db97955-eed7-46b8-b183-eaa3aed597ce': 'work-system-admin',
    'a9ef7039-7f87-40c2-8892-dffe775583a8': 'work-items',
    'b398c70d-b6c0-4ec1-a86b-9e9df5f5d507': 'work-item-new',
  };

  guidId: string;
  // TODO: remove these as they'll be replaced by the new web object "members" field
  tags: Tag[];
  settings: WorkspaceWidgetSetting[];
  name: string;
  widgetTemplateGuidId: string;
  sysWidgetName: string;
  sysWidgetVersion: number;
  workspaceWidgetSettingJson: any;
  readOnly: boolean;
  parentLevel: number;

  members: {
    tags: Tag[];
    settings: WorkspaceWidgetSetting[];
    name: string;
    widgetTemplateGuidId: string;
    sysWidgetName: string;
    sysWidgetVersion: number;
    workspaceWidgetSettingJson: any;
    readOnly: boolean;
    parentLevel: number;
  }

  // gridster fields
  x: number;
  y: number;
  rows: number;
  cols: number;

  // widget bar
  favoriteBarSortOrder: number;
  widgetBarSortOrder: number;

  $baseWidgetGuidId: string;
  $dirty: boolean;
  $ephemereal: boolean;
  $widgetTemplateModuleName: string;

  constructor(item?: Partial<WorkspaceWidget>) {
    super(item);

    this.members = this.members || {} as any;

    this.settings = (this.settings || []).map((x: WorkspaceWidgetSetting) => {
      return new WorkspaceWidgetSetting(x);
    });
    this.members.settings = (this.members.settings || []).map((x: WorkspaceWidgetSetting) => {
      return new WorkspaceWidgetSetting(x);
    });

    const widgetSettingsJson = JSON.parse(this.workspaceWidgetSettingJson || this.members.workspaceWidgetSettingJson || '{}');

    if (!this.cols && !this.rows) {
      this.cols = widgetSettingsJson.cols;
      this.rows = widgetSettingsJson.rows;
      this.x = widgetSettingsJson.x;
      this.y = widgetSettingsJson.y;
    }

    this.favoriteBarSortOrder = widgetSettingsJson.favoriteBarSortOrder;
    this.widgetBarSortOrder = widgetSettingsJson.widgetBarSortOrder;

    this.$widgetTemplateModuleName = WorkspaceWidget.WidgetTemplateMap[this.widgetTemplateGuidId || this.members.widgetTemplateGuidId] || '';
  }

  getSettingValueByName(name: string) {
    const setting = this.getSettingByName(name) || {} as WorkspaceWidgetSetting;
    return setting.$value;
  }

  getSettingByName(name: string) {
    const setting = (this.settings || []).find((s: WorkspaceWidgetSetting) => {
      return s.name === name;
    });
    return setting;
  }

  getSettingsAsKeyValueObject() {
    const settings = {};
    for (const setting of this.settings || []) {
      settings[setting.name] = setting.$value;
    }
    return settings;
  }

  updateWorkspaceWidgetSettingJson() {
    const widgetSettings = {
      cols: this.cols,
      rows: this.rows,
      x: this.x,
      y: this.y,
      widgetBarSortOrder: this.widgetBarSortOrder,
      favoriteBarSortOrder: this.favoriteBarSortOrder,
    };

    this.workspaceWidgetSettingJson = JSON.stringify(widgetSettings);
  }

}
