import { ChangeDetectionStrategy, ChangeDetectorRef, Component, forwardRef, Input, OnInit, ViewChild } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { CalendarDateFormatter, CalendarEvent, CalendarEventTimesChangedEvent } from 'angular-calendar';
import { WorkSchedule, WorkScheduleDay } from 'app/center-v2/shared/models/web-center-types/web-work-schedule.model';
import { BaseDialog } from 'app/shared/dialogs/base/base.dialog';
import { DateTimeIndexUtils } from 'app/shared/utils/date-time-index.utils';
import { addHours, format, getDay, isThisWeek, nextFriday, nextMonday, nextSaturday, nextSunday, nextThursday, nextTuesday, nextWednesday, setHours, setMinutes, startOfDay, startOfWeek } from 'date-fns';
import { Subject } from 'rxjs';
import { EditWorkScheduleDayDialog } from './dialogs/edit-work-schedule-day/edit-work-schedule-day.dialog';
import { CustomDateFormatter } from './custom-date-formatter.provider';



enum DaysOfWeek {
  None = 0, // All
  Sunday = 1 << 0,
  Monday = 1 << 1,
  Tuesday = 1 << 2,
  Wednesday = 1 << 3,
  Thursday = 1 << 4,
  Friday = 1 << 5,
  Saturday = 1 << 6,
}

@Component({
  selector: 'lc-form-field-work-schedule',
  templateUrl: 'form-field-work-schedule.component.html',
  styleUrls: ['./form-field-work-schedule.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => FormFieldWorkScheduleComponent),
    multi: true
  }, {
    provide: CalendarDateFormatter,
    useClass: CustomDateFormatter,
  },],
})
export class FormFieldWorkScheduleComponent implements ControlValueAccessor, OnInit {

  readonly noValueHtml = `<span class="no-value">-</span>`;

  @ViewChild(EditWorkScheduleDayDialog, { static: true }) editWorkScheduleDayDialog: BaseDialog;

  @Input() appendTo: any;
  @Input() disabled: boolean;
  @Input() editMode: boolean;
  @Input() iconLeft: string;
  @Input() iconRight: string;
  @Input() label: string;
  @Input() placeholder: string;
  @Input() required: boolean;

  onChange: (x: any) => {};
  onTouched: () => {};

  events: CalendarEvent[] = [];
  value: WorkSchedule;
  viewDate: Date;
  refreshCalendar = new Subject<void>();


  constructor(
    private cdr: ChangeDetectorRef,
  ) {
    this.viewDate = startOfWeek(new Date(), { weekStartsOn: 1 });
  }

  // From ControlValueAccessor interface
  writeValue(value: any) {
    if (this.value !== value) {
      this.value = JSON.parse(value || '{}');
      this.value.days = this.value.days || {};

      this.parseWorkScheduleToEvents();
      this.cdr.markForCheck();
    }
  }

  // From ControlValueAccessor interface
  registerOnChange(fn: any) {
    this.onChange = fn;
  }

  // From ControlValueAccessor interface
  registerOnTouched(fn: any) {
    this.onTouched = fn;
  }

  ngOnInit() {

  }

  parseWorkScheduleToEvents() {
    this.events = [];
    for (const weekDayIndex of Object.keys(this.value.days)) {
      const daysOfWeekMap = {
        0: nextSunday(this.viewDate),
        1: nextMonday(this.viewDate),
        2: nextTuesday(this.viewDate),
        3: nextWednesday(this.viewDate),
        4: nextThursday(this.viewDate),
        5: nextFriday(this.viewDate),
        6: nextSaturday(this.viewDate),
      };
      const date = isThisWeek(daysOfWeekMap[weekDayIndex], { weekStartsOn: 1 }) ? daysOfWeekMap[weekDayIndex] : this.viewDate;
      const workScheduleDay = this.value.days[weekDayIndex] as WorkScheduleDay;

      const start = workScheduleDay.dayStartTime ? setMinutes(setHours(startOfDay(date), parseInt(workScheduleDay.dayStartTime.split(':')[0])), parseInt(workScheduleDay.dayStartTime.split(':')[1]))
      : DateTimeIndexUtils.decodeSecondsIndexToDate(date, workScheduleDay.dayStartTimeIndex);
      const end = workScheduleDay.dayEndTime ? setMinutes(setHours(startOfDay(date), parseInt(workScheduleDay.dayEndTime.split(':')[0])), parseInt(workScheduleDay.dayEndTime.split(':')[1]))
      : DateTimeIndexUtils.decodeSecondsIndexToDate(date, workScheduleDay.dayEndTimeIndex);

      this.events.push({
        allDay: false,
        draggable: true,
        end: end,
        meta: workScheduleDay,
        start: start,
        title: format(date, 'EEEE'),
      });
    }
  }

  emptySlotClicked(date: Date) {
    this.editWorkScheduleDayDialog.show({
      workScheduleDay: {
        weekDayIndex: 1 << getDay(date),
        dayStartTimeIndex: DateTimeIndexUtils.encodeDateToSecondsIndex(date),
        dayEndTimeIndex: DateTimeIndexUtils.encodeDateToSecondsIndex(addHours(date, 8)),
        items: [],
      } as WorkScheduleDay,
    });
    this.editWorkScheduleDayDialog.onClose = (result: WorkScheduleDay) => {
      if (!result) return;

      if ((result as any).$deleted) {
        const index = this.events.findIndex(x => x.meta?.weekDayIndex === result.weekDayIndex);
        if (index >= 0) {
          this.events.splice(index, 1);
        }
      } else {
        this.value.days[result.weekDayIndex] = result;

        this.events = this.events.filter(x => x.meta?.weekDayIndex !== result.weekDayIndex);
        this.events.push({
          allDay: false,
          draggable: true,
          end: DateTimeIndexUtils.decodeSecondsIndexToDate(date, result.dayEndTimeIndex),
          meta: result,
          start: DateTimeIndexUtils.decodeSecondsIndexToDate(date, result.dayStartTimeIndex),
          title: format(date, 'EEEE'),
        });
      }

      this.refreshCalendar.next();
      this.onChange(JSON.stringify(this.value));
      this.cdr.markForCheck();
    }
  }

  eventClicked({ event }: { event: CalendarEvent }): void {
    const workScheduleDay = event.meta;
    this.editWorkScheduleDayDialog.show({
      workScheduleDay: workScheduleDay,
    });
    this.editWorkScheduleDayDialog.onClose = (result: WorkScheduleDay) => {
      if (!result) return;

      if ((result as any).$deleted) {
        const index = this.events.findIndex(x => x.meta?.weekDayIndex === workScheduleDay.weekDayIndex);
        if (index >= 0) {
          this.events.splice(index, 1);
        }
      } else {
        Object.assign(event.meta, result);

        event.start = result.dayStartTime ? setMinutes(setHours(startOfDay(event.start), parseInt(result.dayStartTime.split(':')[0])), parseInt(result.dayStartTime.split(':')[1]))
        : DateTimeIndexUtils.decodeSecondsIndexToDate(event.start, result.dayStartTimeIndex);
        event.end = result.dayEndTime ? setMinutes(setHours(startOfDay(event.start), parseInt(result.dayEndTime.split(':')[0])), parseInt(result.dayEndTime.split(':')[1]))
        : DateTimeIndexUtils.decodeSecondsIndexToDate(event.start, result.dayEndTimeIndex);
      }

      this.refreshCalendar.next();
      this.onChange(JSON.stringify(this.value));
      this.cdr.markForCheck();
    }
  }

  eventTimesChanged({ event, newStart, newEnd }: CalendarEventTimesChangedEvent) {
    event.meta.dayStartTime = undefined;
    event.meta.dayEndTime = undefined;
    event.meta.dayStartTimeIndex = DateTimeIndexUtils.encodeDateToSecondsIndex(newStart);
    event.meta.dayEndTimeIndex = DateTimeIndexUtils.encodeDateToSecondsIndex(newEnd);
    event.start = newStart;
    event.end = newEnd;
    this.refreshCalendar.next();
    this.onChange(JSON.stringify(this.value));
    this.cdr.markForCheck();
  }

}

