import { WebSkin } from '../../center/shared/models';


export class WebSkinUtils {

  private static webSkinAepCloudGuidId = '3c37c096-7a5c-4485-8dbf-9c7780ac4fc9';
  private static webSkinKeyportalGuidId = 'b2274d06-b907-4017-87b9-567393e16093';
  private static webSkinLogicCenterGuidId = 'dd7cc557-1e84-49a1-8e10-bd3e8d318137';
  private static webSkinPreemGuidId = '638f04be-1416-4284-b4f0-ecffc5a833f1';
  private static webSkinSatoGuidId = '524a5e1d-fccb-4490-98c7-502fe8261dff';
  private static webSkinServlyGuidId = '627a3490-f3c5-4414-b7e7-5feec102f37a';

  // HARDCODED SKINS
  private static webSkinMap: { [key:string]: WebSkin } = {
    [WebSkinUtils.webSkinLogicCenterGuidId]: { // MobileLogic
      guidId: WebSkinUtils.webSkinLogicCenterGuidId,
      appTitle: 'LogicCenter',
      theme: 'mobilelogic',
      language: 'en',
    },
    [WebSkinUtils.webSkinKeyportalGuidId]: { // Keyportal / TopFuel
      guidId: WebSkinUtils.webSkinKeyportalGuidId,
      appTitle: 'Keyportal',
      theme: 'keyportal',
      language: 'en',
      supportPage: {
        title: 'Top Fuel Support',
        routerLink: '/volymkoll/support',
      },
    },
    [WebSkinUtils.webSkinPreemGuidId]: { // Preem / Volymkoll
      guidId: WebSkinUtils.webSkinPreemGuidId,
      appTitle: 'Preem',
      theme: 'preem',
      language: 'se',
      customLogin: 'volymkoll/login',
      supportPage: {
        title: 'Top Fuel Support',
        routerLink: '/volymkoll/support',
      },
    },
    [WebSkinUtils.webSkinAepCloudGuidId]: { // AEP Cloud
      guidId: WebSkinUtils.webSkinSatoGuidId,
      appTitle: 'AEP Cloud',
      customLogin: 'aep-cloud-home',
      theme: 'aep-cloud',
      language: 'en',
    },
    [WebSkinUtils.webSkinSatoGuidId]: { // Sato
      guidId: WebSkinUtils.webSkinSatoGuidId,
      appTitle: 'SATO',
      customLogin: 'v2/auth/sato/login',
      theme: 'sato',
      language: 'en',
    },
    [WebSkinUtils.webSkinServlyGuidId]: { // Servly
      guidId: WebSkinUtils.webSkinServlyGuidId,
      appTitle: 'Servly Online',
      customLogin: 'v2/auth/servly/login',
      theme: 'servly',
      language: 'se',
    },
  };
  // HARDCODED SKINS

  static getDefaultWebSkin(): WebSkin {
    return this.webSkinMap[WebSkinUtils.webSkinLogicCenterGuidId];
  }

  static getWebSkinForCurrentUrl(url: string): WebSkin | null {
    if (url.indexOf('keyportal.se') >= 0) {
      return this.webSkinMap[WebSkinUtils.webSkinKeyportalGuidId];
    } else if (url.indexOf('volymkoll.se') >= 0) {
      return this.webSkinMap[WebSkinUtils.webSkinPreemGuidId];
    } else if (url.indexOf('aepcloud.logiccenter.com') >= 0 || url.indexOf('aep-cloud.com') >= 0) {
      return this.webSkinMap[WebSkinUtils.webSkinAepCloudGuidId];
    } else if (url.indexOf('sato.logiccenter.com') >= 0) {
      return this.webSkinMap[WebSkinUtils.webSkinSatoGuidId];
    } else if (url.indexOf('servlyonline.se') >= 0) {
      return this.webSkinMap[WebSkinUtils.webSkinServlyGuidId];
    }

    return null;
  }

  static getWebSkin(webSkinGuidId: string): WebSkin | null {
    return this.webSkinMap[webSkinGuidId] || null;
  }

}
